import React, { useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
  useMediaQuery,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Chip,
  CircularProgress,
  IconButton,
  Badge,
  Avatar,
  Fab,
} from '@mui/material';
import { motion, useScroll, useTransform } from 'framer-motion';
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  AccountBalance as AccountBalanceIcon,
  Engineering as EngineeringIcon,
  Business as BusinessIcon,
  Apartment as ApartmentIcon,
  Construction as ConstructionIcon,
  Warning as WarningIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
  Lock as LockIcon,
  ArrowBack as ArrowBackIcon,
  Download as DownloadIcon,
  Search as SearchIcon,
  Notifications as NotificationsIcon,
  Dashboard as DashboardIcon,
  Folder as FolderIcon,
  History as HistoryIcon,
  Person as PersonIcon,
  Settings as SettingsIcon,
  SmartToy as SmartToyIcon,
  Logout as LogoutIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import PieChartIcon from '@mui/icons-material/PieChart';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DescriptionIcon from '@mui/icons-material/Description';
import { alpha } from '@mui/material/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import logo from '../assets/logo.png';
import tech from '../assets/tech.jpg';
import scale from '../assets/scale.jpg';
import inpact from '../assets/inpact.jpg';
import DocumentComplianceReport from './DocumentComplianceReport';
import DocumentCompliancePreview from '../components/DocumentCompliancePreview';

const Home: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { scrollYProgress } = useScroll();

  // Scroll-based animations
  useEffect(() => {
    document.body.style.overflow = 'auto';
    document.body.style.scrollSnapType = 'y mandatory';
    
    return () => {
      document.body.style.overflow = '';
      document.body.style.scrollSnapType = '';
    };
  }, []);

  // Scroll handler for smooth navigation
  const scrollToSection = (sectionId: string) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Navigation items with section IDs
  const navItems = [
    { label: 'Home', onClick: () => window.scrollTo({ top: 0, behavior: 'smooth' }) },
    { label: 'Who We Serve', onClick: () => scrollToSection('who-we-serve') },
    { label: 'Features', onClick: () => scrollToSection('features') },
    { label: 'Our Story', onClick: () => scrollToSection('our-story') },
    { label: 'Early Access', onClick: () => scrollToSection('early-access') },
  ];

  const stakeholders = [
    {
      icon: <AccountBalanceIcon sx={{ fontSize: 48, color: theme.palette.primary.main }} />,
      title: 'City Governments & Permit Offices',
      features: [
        'Streamline permit processing with AI-driven automation',
        'Reduce administrative workload by up to 70%',
        'Improve accuracy and consistency of permit reviews'
      ],
    },
    {
      icon: <EngineeringIcon sx={{ fontSize: 48, color: theme.palette.primary.main }} />,
      title: 'Architects',
      features: [
        'Automatic form completion and compliance checks',
        'Reduce submission errors by 85%',
        'Faster project initiation and approval times'
      ],
    },
    {
      icon: <EngineeringIcon sx={{ fontSize: 48, color: theme.palette.primary.main }} />,
      title: 'Engineers',
      features: [
        'AI-powered code compliance verification',
        'Instant regulatory cross-referencing',
        'Minimize compliance-related project delays'
      ],
    },
    {
      icon: <BusinessIcon sx={{ fontSize: 48, color: theme.palette.primary.main }} />,
      title: 'Builders & Developers',
      features: [
        'Predictable and accelerated permit workflows',
        'Significant cost savings on administrative processes',
        'Real-time status tracking and updates'
      ],
    },
    {
      icon: <ApartmentIcon sx={{ fontSize: 48, color: theme.palette.primary.main }} />,
      title: 'Property Owners',
      features: [
        'Simplified permit application experience',
        'Clear, real-time communication about permit status',
        'Reduced time and complexity in property development'
      ],
    },
    {
      icon: <ConstructionIcon sx={{ fontSize: 48, color: theme.palette.primary.main }} />,
      title: 'General Contractors',
      features: [
        'Centralized permit management platform',
        'Automated compliance and submission tracking',
        'Seamless collaboration with all project stakeholders'
      ],
    },
  ];

  const automationFeatures = [
    {
      title: 'Smart Form Filling with AI',
      stats: { pending: 8, queued: 4, active: 12 },
      description: 'Automate repetitive fields and reduce permit form submission time by up to 85% with intelligent, AI-powered data entry.',
      image: '/smart-form-preview.png'
    },
    {
      title: 'Automated Code Compliance',
      stats: { total: 16, pass: 12, fail: 2, partial: 2 },
      description: 'Instantly verify building codes and cross-check regulations to ensure faster, error-free permit approvals.',
      image: '/code-compliance-preview.png'
    },
    {
      title: 'Real-Time Permit Status Tracking',
      description: 'Stay informed with transparent, automated updates at every stage of the permit application lifecycle.',
      image: '/status-tracking-preview.png'
    }
  ];

  // Add sample data
  const sampleReport = {
    document_name: "Building Plans - Second Floor",
    version: "1.0",
    total_checks: 12,
    passed_checks: 4,
    failed_checks: 3,
    partial_checks: 5,
    created_at: new Date().toISOString(),
    jurisdiction: "San Francisco, CA",
    code_version: "SF Building Code 2022",
    submission_status: "In Review",
    permit_id: "PERMIT-123",
    critical_issues: [
      "Emergency egress path width does not meet minimum requirements",
      "Fire safety compliance issues in multiple sections",
      "Structural load calculations need revision"
    ],
    checks: [
      {
        checkName: "Emergency Exit Width",
        status: "Fail",
        notes: "Exit width is 36 inches, below required 48 inches minimum"
      },
      {
        checkName: "Fire Safety Systems",
        status: "Partial Pass",
        notes: "Sprinkler system layout acceptable, but additional coverage needed in storage areas"
      },
      {
        checkName: "Structural Load",
        status: "Pass",
        notes: "All load-bearing calculations within acceptable ranges"
      }
    ]
  };

  return (
    <Box sx={{ bgcolor: 'background.default' }}>
      {/* Floating Header */}
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
          bgcolor: 'rgba(255, 255, 255, 0.9)',
          backdropFilter: 'blur(10px)',
          borderBottom: '1px solid',
          borderColor: 'divider',
          py: 1.5,
        }}
      >
        <Container maxWidth="lg">
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between'
          }}>
            {/* Logo */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <img src={logo} alt="Augence" style={{ height: 52 }} />
            </Box>

            {/* Navigation */}
            <Box sx={{ 
              display: { xs: 'none', md: 'flex' }, 
              gap: 4,
              alignItems: 'center'
            }}>
              {navItems.map((item, index) => (
                <Typography
                  key={index}
                  component="button"
                  onClick={item.onClick}
                  sx={{
                    color: 'text.primary',
                    textDecoration: 'none',
                    fontWeight: 500,
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    padding: 0,
                    font: 'inherit',
                    '&:hover': {
                      color: 'primary.main',
                    },
                  }}
                >
                  {item.label}
                </Typography>
              ))}
            </Box>

            {/* Action Buttons */}
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                variant="text"
                onClick={() => navigate('/signin')}
                sx={{
                  color: 'text.primary',
                  fontWeight: 500,
                }}
              >
                Sign in
              </Button>
              <Button
                variant="contained"
                onClick={() => navigate('/signup')}
                sx={{
                  bgcolor: 'primary.main',
          color: 'white',
                  fontWeight: 500,
                  '&:hover': {
                    bgcolor: 'primary.dark',
                  },
                }}
              >
                Try Now
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>

      {/* Hero Section */}
      <Box sx={{ 
        bgcolor: 'background.paper',
          minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        pt: 10,
          position: 'relative',
          overflow: 'hidden',
      }}>
        {/* Background decorative elements */}
        <Box
          sx={{
            position: 'absolute',
            top: '15%',
            right: '5%',
            width: '40%',
            height: '40%',
            background: `radial-gradient(circle, ${alpha(theme.palette.primary.main, 0.03)} 0%, rgba(255,255,255,0) 70%)`,
            borderRadius: '50%',
            filter: 'blur(60px)',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            bottom: '15%',
            left: '5%',
            width: '30%',
            height: '30%',
            background: `radial-gradient(circle, ${alpha(theme.palette.secondary.main, 0.03)} 0%, rgba(255,255,255,0) 70%)`,
            borderRadius: '50%',
            filter: 'blur(60px)',
          }}
        />

        {/* What's new banner */}
        <Box
                sx={{
            display: 'flex',
            justifyContent: 'center',
            mb: 6,
                    position: 'relative',
          }}
        >
                <Box
                  sx={{
              display: 'inline-block',
              bgcolor: alpha(theme.palette.primary.main, 0.05),
              borderRadius: '30px',
              px: 3,
              py: 1,
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 600,
                color: theme.palette.primary.main,
                textTransform: 'uppercase',
                letterSpacing: 1,
                fontSize: '0.875rem',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Box component="span" sx={{ color: theme.palette.primary.main }}>🚀</Box>
              Join Us Now
            </Typography>
              </Box>
          </Box>

        <Container maxWidth="lg">
          <Box sx={{ textAlign: 'center', mb: 6, position: 'relative' }}>
                <Typography
              variant="h1"
                  sx={{
                    mb: 3,
                fontWeight: 800,
                background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontSize: { xs: '2.5rem', md: '4rem' },
                    lineHeight: 1.2,
                  }}
                >
              AI-Driven Permit Solutions for<br />
                  Cities & Developers
              </Typography>
              <Typography
              variant="h6"
                sx={{
                mb: 8,
                color: 'text.secondary',
                maxWidth: '800px',
                mx: 'auto',
                lineHeight: 1.6,
                fontWeight: 'normal',
                fontSize: '1.25rem',
              }}
            >
              Streamline building permit applications, accelerate approvals, and ensure code compliance with
              Augence — the leading solution for automated permit processing and real-time status tracking.
              </Typography>
            </Box>

          <Box sx={{ 
            position: 'relative',
            maxWidth: { xs: '100%', md: '1200px' },
            mx: 'auto',
            transform: { xs: 'scale(1)', md: 'scale(0.95)' },
            transformOrigin: 'top center'
          }}>
            {/* Browser Frame */}
            <Box
                    sx={{
                maxWidth: '100%',
                mx: 'auto',
                bgcolor: 'background.paper',
                borderRadius: { xs: 0, md: '12px' },
                overflow: 'hidden',
                boxShadow: { xs: 'none', md: '0 12px 24px rgba(0, 0, 0, 0.1)' },
              }}
            >
              {/* Browser Header */}
                <Box
                  sx={{
                  display: { xs: 'none', md: 'flex' },
                  bgcolor: '#f0f0f0',
                  p: 1,
                  alignItems: 'center',
                  gap: 1,
                  borderBottom: '1px solid #e0e0e0'
                }}
              >
                <Box sx={{ display: 'flex', gap: 0.5, ml: 1 }}>
                  {['#ff5f57', '#ffbd2e', '#28c940'].map((color) => (
                    <Box
                      key={color}
                    sx={{
                        width: 12,
                        height: 12,
                        borderRadius: '50%',
                        bgcolor: color
                      }}
                    />
                  ))}
                </Box>
                            <Box
                sx={{
                    flex: 1,
                    mx: 1,
                    bgcolor: '#fff',
                    borderRadius: 1,
                    p: 0.5,
                    px: 2,
                    fontSize: '13px',
                    color: '#666',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                  }}
                >
                  <LockIcon sx={{ fontSize: 14 }} />
                  app.augence.ai/compliance-report
                          </Box>
                    </Box>
                    
              {/* Application Layout */}
              <Box sx={{ 
                display: 'flex', 
                height: { xs: 'calc(100vh - 100px)', md: '800px' }
              }}>
                {/* Sidebar */}
                          <Box
              sx={{
                    width: { xs: '56px', md: '72px' },
                    bgcolor: '#ffffff',
                    borderRight: '1px solid',
                    borderColor: 'divider',
                display: 'flex',
                    flexDirection: 'column',
                alignItems: 'center',
                    py: { xs: 1, md: 2 },
                    gap: { xs: 1, md: 2 }
                  }}
                >
                  <Box sx={{ p: 1 }}>
                    <img src={logo} alt="Augence" style={{ height: 24 }} />
                  </Box>
                  {[
                    { icon: <DashboardIcon />, active: false, path: '/dashboard' },
                    { icon: <FolderIcon />, active: false, path: '/projects' },
                    { icon: <DescriptionIcon />, active: true, path: '/documents' },
                    { icon: <CheckCircleOutlineIcon />, active: false, path: '/approvals' },
                    { icon: <HistoryIcon />, active: false, path: '/history' },
                    { icon: <PersonIcon />, active: false, path: '/profile' },
                    { icon: <SettingsIcon />, active: false, path: '/settings' },
                    { icon: <SmartToyIcon />, active: false, path: '/saru' },
                  ].map((item, index) => (
                    <Box
                      key={index}
                sx={{
                        p: 1,
                        borderRadius: 1,
                        bgcolor: item.active ? alpha(theme.palette.primary.main, 0.08) : 'transparent',
                        color: item.active ? theme.palette.primary.main : 'text.secondary',
                        cursor: 'pointer',
                        '&:hover': {
                          bgcolor: alpha(theme.palette.primary.main, 0.08),
                        }
                      }}
                    >
                      {item.icon}
                    </Box>
                  ))}
                  <Box sx={{ mt: 'auto' }}>
                            <Box
                sx={{
                        p: 1,
                        borderRadius: 1,
                        color: 'text.secondary',
                        cursor: 'pointer',
                        '&:hover': {
                          bgcolor: alpha(theme.palette.primary.main, 0.08),
                        }
                      }}
                    >
                      <LogoutIcon />
                          </Box>
                  </Box>
                    </Box>
                    
                {/* Main Content Area */}
                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                  {/* Header */}
                  <Box
                                  sx={{
                      height: { xs: '56px', md: '64px' },
                      borderBottom: '1px solid',
                      borderColor: 'divider',
                      px: { xs: 2, md: 3 },
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                      bgcolor: '#ffffff'
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <IconButton
                        sx={{
                          display: { xs: 'none', md: 'flex' },
                          bgcolor: alpha(theme.palette.primary.main, 0.04),
                                    '&:hover': {
                            bgcolor: alpha(theme.palette.primary.main, 0.08),
                          }
                                  }}
                                >
                        <ArrowBackIcon />
                      </IconButton>
                                  <Box>
                        <Typography variant="h6" sx={{ 
                          color: 'text.primary', 
                          fontWeight: 600,
                          fontSize: { xs: '1rem', md: '1.25rem' }
                        }}>
                          Compliance Report
              </Typography>
                        <Typography variant="caption" color="text.secondary">
                          Building Permit
                                    </Typography>
                                  </Box>
                      <Chip 
                        label="In Review" 
                        size="small" 
                        color="warning"
                        sx={{ 
                          ml: 1,
                          display: { xs: 'none', sm: 'flex' }
                        }}
                      />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: { xs: 1, md: 2 } }}>
                      <Box
                        sx={{
                          display: { xs: 'none', md: 'flex' },
                          alignItems: 'center',
                          bgcolor: alpha(theme.palette.primary.main, 0.04),
                          borderRadius: '8px',
                          px: 2,
                          py: 1,
                          width: '240px'
                        }}
                      >
                        <SearchIcon sx={{ color: 'text.secondary', mr: 1 }} />
                        <Typography variant="body2" color="text.secondary">Search...</Typography>
                      </Box>
                      <IconButton size="small">
                        <Badge badgeContent={2} color="error">
                          <NotificationsIcon />
                        </Badge>
                      </IconButton>
                      <Avatar 
                        sx={{ 
                          width: { xs: 28, md: 32 }, 
                          height: { xs: 28, md: 32 }, 
                          bgcolor: theme.palette.primary.main,
                          fontSize: { xs: '0.75rem', md: '0.875rem' },
                          fontWeight: 600
                        }}
                      >
                        MA
                      </Avatar>
                    </Box>
                  </Box>

                  {/* Content Area */}
                  <Box sx={{ 
                    flex: 1, 
                    bgcolor: '#F8F9FA',
                    position: 'relative',
                    overflow: 'hidden'
                  }}>
                    <DocumentCompliancePreview />

                    {/* AI Chat Button */}
                    <Box
                      sx={{
                        position: 'absolute',
                        bottom: { xs: 16, md: 24 },
                        right: { xs: 16, md: 24 },
                      }}
                    >
                      <Fab
                        size={isMobile ? "medium" : "large"}
                        sx={{
                          bgcolor: '#D35400',
                          color: 'white',
                          '&:hover': {
                            bgcolor: '#A04000',
                          }
                        }}
                      >
                        <SmartToyIcon sx={{ fontSize: { xs: 20, md: 24 } }} />
                      </Fab>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>

      {/* Stakeholders Section */}
      <Box
        id="who-we-serve"
        sx={{
          py: { xs: 8, md: 12 },
          minHeight: '100vh',
          scrollSnapAlign: 'start',
          display: 'flex',
          alignItems: 'center',
          background: 'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(248,249,250,1) 100%)',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        {/* Background decorative elements */}
        <Box
          sx={{
            position: 'absolute',
            top: '5%',
            left: '5%',
            width: '40%',
            height: '40%',
            background: `radial-gradient(circle, ${alpha(theme.palette.primary.main, 0.03)} 0%, rgba(255,255,255,0) 70%)`,
            borderRadius: '50%',
            filter: 'blur(60px)',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            bottom: '5%',
            right: '5%',
            width: '30%',
            height: '30%',
            background: `radial-gradient(circle, ${alpha(theme.palette.secondary.main, 0.03)} 0%, rgba(255,255,255,0) 70%)`,
            borderRadius: '50%',
            filter: 'blur(60px)',
          }}
        />

        <Container maxWidth="lg">
          <Box sx={{ textAlign: 'center', mb: 8, position: 'relative' }}>
            <Box
              sx={{
                display: 'inline-block',
                bgcolor: alpha(theme.palette.primary.main, 0.05),
                borderRadius: '30px',
                px: 3,
                py: 1,
                mb: 3,
              }}
            >
            <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 600,
                  color: theme.palette.primary.main,
                  textTransform: 'uppercase',
                  letterSpacing: 1,
                  fontSize: '0.875rem',
                }}
              >
                Who We Serve
              </Typography>
            </Box>
            
            <Typography
              variant="h2"
              sx={{ 
                mb: 3,
                fontWeight: 800,
                background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontSize: { xs: '2rem', md: '3rem' },
                lineHeight: 1.2,
              }}
          >
            Helping Cities, Architects, Engineers,
              <br />
            and Builders Streamline Permits
            </Typography>

            <Typography
              variant="h6"
              sx={{ 
                mb: 8,
                color: 'text.secondary',
                maxWidth: '800px',
                mx: 'auto',
                lineHeight: 1.6,
                fontWeight: 'normal',
              }}
          >
            Augence supports every stakeholder in the permitting process — from municipal governments to
            developers — with intelligent automation that simplifies approvals, ensures compliance, and saves time.
            </Typography>

          <Grid container spacing={4}>
            {stakeholders.map((stakeholder, index) => (
              <Grid item xs={12} md={4} key={index}>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                    <Paper
                      elevation={0}
                    sx={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                        p: 4,
                        borderRadius: '16px',
                        border: '1px solid',
                        borderColor: 'divider',
                        bgcolor: 'background.paper',
                        position: 'relative',
                        overflow: 'hidden',
                      '&:hover': {
                          borderColor: 'transparent',
                          boxShadow: `0 12px 28px ${alpha(theme.palette.primary.main, 0.12)}`,
                          transform: 'translateY(-8px)',
                        transition: 'all 0.3s ease',
                          '& .icon-wrapper': {
                            transform: 'scale(1.1)',
                            bgcolor: alpha(theme.palette.primary.main, 0.08),
                          }
                      },
                    }}
                  >
                      {/* Decorative top border */}
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          height: 4,
                          background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                        }}
                      />

                      {/* Icon with background */}
                      <Box
                        className="icon-wrapper"
                        sx={{
                          mb: 3,
                          p: 2,
                          borderRadius: '12px',
                          bgcolor: alpha(theme.palette.primary.main, 0.05),
                          transition: 'all 0.3s ease',
                        }}
                      >
                        {React.cloneElement(stakeholder.icon, {
                          style: { fontSize: 40, color: theme.palette.primary.main }
                        })}
                      </Box>

                      <Typography 
                        variant="h5" 
                        component="h3" 
                        sx={{ 
                          mb: 3,
                          fontWeight: 700,
                          color: 'text.primary',
                        }}
                      >
                      {stakeholder.title}
                    </Typography>

                      <List sx={{ width: '100%', mt: 'auto' }}>
                      {stakeholder.features.map((feature, featureIndex) => (
                          <ListItem 
                            key={featureIndex} 
                            sx={{ 
                              px: 0,
                              py: 1,
                            }}
                          >
                          <ListItemIcon sx={{ minWidth: 36 }}>
                              <Box
                                sx={{
                                  width: 24,
                                  height: 24,
                                  borderRadius: '50%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  bgcolor: alpha(theme.palette.primary.main, 0.08),
                                }}
                              >
                                <CheckCircleOutlineIcon 
                                  sx={{ 
                                    fontSize: 16,
                                    color: theme.palette.primary.main,
                                  }} 
                                />
                              </Box>
                          </ListItemIcon>
                          <ListItemText 
                            primary={feature}
                      sx={{
                              '& .MuiListItemText-primary': { 
                                  fontSize: '0.95rem',
                                  textAlign: 'left',
                                  color: 'text.secondary',
                                  lineHeight: 1.5,
                              }
                            }}
                          />
                        </ListItem>
                      ))}
                    </List>
                    </Paper>
                </motion.div>
              </Grid>
            ))}
          </Grid>
          </Box>
        </Container>
                    </Box>

      {/* AI-Powered Automation Section */}
      <Box
        id="features"
                      sx={{
          bgcolor: 'background.paper',
          py: { xs: 8, md: 12 },
          minHeight: '100vh',
          scrollSnapAlign: 'start',
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        {/* Background decorative elements */}
        <Box
          sx={{
            position: 'absolute',
            top: '10%',
            right: '5%',
            width: '40%',
            height: '40%',
            background: `radial-gradient(circle, ${alpha(theme.palette.primary.main, 0.03)} 0%, rgba(255,255,255,0) 70%)`,
            borderRadius: '50%',
            filter: 'blur(60px)',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            bottom: '10%',
            left: '5%',
            width: '30%',
            height: '30%',
            background: `radial-gradient(circle, ${alpha(theme.palette.secondary.main, 0.03)} 0%, rgba(255,255,255,0) 70%)`,
            borderRadius: '50%',
            filter: 'blur(60px)',
          }}
        />

        <Container maxWidth="lg">
          <Box sx={{ textAlign: 'center', mb: 8, position: 'relative' }}>
            <Box
              sx={{
                display: 'inline-block',
                bgcolor: alpha(theme.palette.primary.main, 0.05),
                borderRadius: '30px',
                px: 3,
                py: 1,
                mb: 3,
              }}
            >
          <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 600,
                  color: theme.palette.primary.main,
                  textTransform: 'uppercase',
                  letterSpacing: 1,
                  fontSize: '0.875rem',
                }}
              >
                AI-Powered Features
              </Typography>
            </Box>
            
            <Typography
              variant="h2"
              sx={{ 
                mb: 3,
                fontWeight: 800,
                background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontSize: { xs: '2rem', md: '3rem' },
                lineHeight: 1.2,
              }}
          >
            AI-Powered Permit Automation for
            <br />
            Governments and Construction Professionals
                    </Typography>

                    <Typography
              variant="h6"
              sx={{ 
                mb: 8,
                color: 'text.secondary',
                maxWidth: '800px',
                mx: 'auto',
                lineHeight: 1.6,
                fontWeight: 'normal',
              }}
          >
            Augence uses advanced artificial intelligence to revolutionize building permit workflows — reducing
            manual tasks, accelerating approvals, and delivering real-time insights from submission to sign-off.
          </Typography>

          <Grid container spacing={4}>
              {/* Automated Code Compliance */}
            <Grid item xs={12} md={4}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Paper
                  elevation={0}
                      sx={{
                      p: 4,
                    height: '100%',
                      borderRadius: '16px',
                      border: '1px solid',
                      borderColor: 'divider',
                      bgcolor: 'background.paper',
                      position: 'relative',
                      overflow: 'hidden',
                    '&:hover': {
                        borderColor: 'transparent',
                        boxShadow: `0 12px 28px ${alpha(theme.palette.primary.main, 0.12)}`,
                        transform: 'translateY(-8px)',
                      transition: 'all 0.3s ease',
                        '& .preview-wrapper': {
                          transform: 'scale(1.02)',
                        }
                    },
                  }}
                >
                    {/* Decorative top border */}
                            <Box
                              sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        height: 4,
                        background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                      }}
                    />

                    <Box className="preview-wrapper" sx={{ mb: 3, transition: 'all 0.3s ease' }}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 3,
                          border: '1px solid',
                          borderColor: 'divider',
                          borderRadius: '12px',
                          bgcolor: alpha(theme.palette.background.paper, 0.6),
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                            <Typography variant="subtitle2" sx={{ fontWeight: 600, flex: 1 }}>
                              Code Compliance Check
                            </Typography>
                            <Chip
                              label="16 Total Checks"
                              size="small"
                              sx={{ bgcolor: alpha(theme.palette.primary.main, 0.1), color: theme.palette.primary.main }}
                            />
                          </Box>
                          <Grid container spacing={1}>
                            <Grid item xs={6}>
                              <Box
        sx={{
                                  p: 1.5,
                                    borderRadius: '8px',
                                  bgcolor: alpha('#4CAF50', 0.1),
                                  textAlign: 'center',
                                }}
                              >
                                <Typography variant="h6" sx={{ fontWeight: 600, color: '#2e7d32' }}>
                                  12
                                </Typography>
                                <Typography variant="caption" sx={{ color: '#2e7d32' }}>
                                  Passed
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={3}>
                              <Box
              sx={{
                                  p: 1.5,
                                    borderRadius: '8px',
                                  bgcolor: alpha('#f44336', 0.1),
                                  textAlign: 'center',
                                }}
                              >
                                <Typography variant="h6" sx={{ fontWeight: 600, color: '#d32f2f' }}>
                                  2
            </Typography>
                                <Typography variant="caption" sx={{ color: '#d32f2f' }}>
                                  Failed
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={3}>
                              <Box
              sx={{
                                  p: 1.5,
                                    borderRadius: '8px',
                                  bgcolor: alpha('#ff9800', 0.1),
                                  textAlign: 'center',
                                }}
                              >
                                <Typography variant="h6" sx={{ fontWeight: 600, color: '#ed6c02' }}>
                                  2
            </Typography>
                                <Typography variant="caption" sx={{ color: '#ed6c02' }}>
                                  Partial
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Box>

                    <Typography variant="h5" sx={{ mb: 2, fontWeight: 700, color: 'text.primary' }}>
                    Automated Code Compliance
                  </Typography>
                    <Typography variant="body1" sx={{ color: 'text.secondary', lineHeight: 1.6 }}>
                    Instantly verify building codes and cross-check regulations to ensure faster, error-free permit approvals.
                  </Typography>
                </Paper>
              </motion.div>
            </Grid>

            {/* Real-Time Permit Status Tracking */}
            <Grid item xs={12} md={4}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.1 }}
              >
                <Paper
                  elevation={0}
              sx={{
                      p: 4,
                    height: '100%',
                      borderRadius: '16px',
                      border: '1px solid',
                      borderColor: 'divider',
                      bgcolor: 'background.paper',
                      position: 'relative',
                      overflow: 'hidden',
                    '&:hover': {
                        borderColor: 'transparent',
                        boxShadow: `0 12px 28px ${alpha(theme.palette.primary.main, 0.12)}`,
                        transform: 'translateY(-8px)',
                      transition: 'all 0.3s ease',
                        '& .preview-wrapper': {
                          transform: 'scale(1.02)',
                        }
                    },
                  }}
                >
                    {/* Decorative top border */}
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        height: 4,
                        background: `linear-gradient(90deg, ${theme.palette.secondary.main}, ${theme.palette.primary.main})`,
                      }}
                    />

                    <Box className="preview-wrapper" sx={{ mb: 3, transition: 'all 0.3s ease' }}>
                    <Paper
                      elevation={0}
                      sx={{
                          p: 3,
                          border: '1px solid',
                          borderColor: 'divider',
                          borderRadius: '12px',
                          bgcolor: alpha(theme.palette.background.paper, 0.6),
                      }}
                    >
                      <List sx={{ py: 0 }}>
                        {[
                          { title: 'Initial Review', status: 'completed', time: '2d ago' },
                          { title: 'Code Compliance', status: 'in_progress', time: 'Now' },
                          { title: 'Final Approval', status: 'pending', time: 'Upcoming' },
                        ].map((item, index) => (
                          <ListItem
                            key={index}
                            sx={{
                              px: 0,
                              py: 1,
                              borderBottom: index !== 2 ? '1px solid rgba(0,0,0,0.1)' : 'none',
                            }}
                          >
                            <ListItemText
                              primary={item.title}
                              secondary={item.time}
                              sx={{
                                '& .MuiListItemText-primary': {
                                  fontWeight: 500,
                                  fontSize: '0.9rem',
                                },
                              }}
                            />
                            <Chip
                              label={item.status.replace('_', ' ')}
                              size="small"
                              sx={{
                                  bgcolor: item.status === 'completed' ? alpha('#4CAF50', 0.1) :
                                          item.status === 'in_progress' ? alpha('#2196F3', 0.1) :
                                          alpha('#FF9800', 0.1),
                                color: item.status === 'completed' ? '#2e7d32' :
                                      item.status === 'in_progress' ? '#1565c0' :
                                      '#ed6c02',
                                textTransform: 'capitalize',
                              }}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Paper>
                  </Box>

                    <Typography variant="h5" sx={{ mb: 2, fontWeight: 700, color: 'text.primary' }}>
                      Real-Time Status Tracking
            </Typography>
                    <Typography variant="body1" sx={{ color: 'text.secondary', lineHeight: 1.6 }}>
                    Stay informed with transparent, automated updates at every stage of the permit application lifecycle.
                  </Typography>
                </Paper>
          </motion.div>
            </Grid>

              {/* Smart Form Filling with AI */}
              <Grid item xs={12} md={4}>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.2 }}
                >
                  <Paper
                    elevation={0}
                    sx={{
                      p: 4,
                      height: '100%',
                      borderRadius: '16px',
                      border: '1px solid',
                      borderColor: 'divider',
                      bgcolor: 'background.paper',
                      position: 'relative',
                      overflow: 'hidden',
                      '&:hover': {
                        borderColor: 'transparent',
                        boxShadow: `0 12px 28px ${alpha(theme.palette.primary.main, 0.12)}`,
                        transform: 'translateY(-8px)',
                        transition: 'all 0.3s ease',
                        '& .preview-wrapper': {
                          transform: 'scale(1.02)',
                        }
                      },
                    }}
                  >
                    {/* Decorative top border */}
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        height: 4,
                        background: `linear-gradient(90deg, ${theme.palette.primary.light}, ${theme.palette.secondary.main})`,
                      }}
                    />

                    <Box className="preview-wrapper" sx={{ mb: 3, transition: 'all 0.3s ease' }}>
                      <Paper
                        elevation={0}
                        sx={{
                          p: 3,
                          border: '1px solid',
                          borderColor: 'divider',
                          borderRadius: '12px',
                          bgcolor: alpha(theme.palette.background.paper, 0.6),
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                              <Typography variant="subtitle2" sx={{ fontWeight: 600, flex: 1 }}>
                                Smart Form Progress
                              </Typography>
                              <Typography variant="caption" color="text.secondary">
                                85% Complete
                              </Typography>
                            </Box>
                            <Box sx={{ width: '100%', bgcolor: alpha(theme.palette.primary.main, 0.1), height: 8, borderRadius: 4, mb: 2 }}>
                              <Box
                                sx={{
                                  width: '85%',
                                  height: '100%',
                                  bgcolor: theme.palette.primary.main,
                                  borderRadius: 4,
                                }}
                              />
                            </Box>
                            <Box sx={{ display: 'flex', gap: 2 }}>
                              {[
                                { label: 'Pending', value: '8' },
                                { label: 'Queued', value: '4' },
                                { label: 'Active', value: '12' },
                              ].map((stat, index) => (
                                <Box
                                  key={index}
                                  sx={{
                                    flex: 1,
                                    p: 1.5,
                                    borderRadius: '8px',
                                    bgcolor: alpha(theme.palette.primary.main, 0.05),
                                    textAlign: 'center',
                                  }}
                                >
                                  <Typography variant="h6" sx={{ fontWeight: 600, color: theme.palette.primary.main }}>
                                    {stat.value}
                                  </Typography>
                                  <Typography variant="caption" color="text.secondary">
                                    {stat.label}
                                  </Typography>
                                </Box>
                              ))}
                            </Box>
          </Grid>
                        </Grid>
                      </Paper>
                    </Box>

                    <Typography variant="h5" sx={{ mb: 2, fontWeight: 700, color: 'text.primary' }}>
                      Smart Form Filling with AI
                    </Typography>
                    <Typography variant="body1" sx={{ color: 'text.secondary', lineHeight: 1.6 }}>
                      Automate repetitive fields and reduce permit form submission time by up to 85% with intelligent, AI-powered data entry.
                    </Typography>
                  </Paper>
                </motion.div>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>

      {/* Founders Section */}
      <Box
        id="our-story"
        sx={{
          py: { xs: 8, md: 12 },
          minHeight: '100vh',
          scrollSnapAlign: 'start',
          display: 'flex',
          alignItems: 'center',
          background: 'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(248,249,250,1) 100%)',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        {/* Background decorative elements */}
        <Box
          sx={{
            position: 'absolute',
            top: '10%',
            right: '5%',
            width: '40%',
            height: '40%',
            background: `radial-gradient(circle, ${alpha(theme.palette.primary.main, 0.03)} 0%, rgba(255,255,255,0) 70%)`,
            borderRadius: '50%',
            filter: 'blur(60px)',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            bottom: '10%',
            left: '5%',
            width: '30%',
            height: '30%',
            background: `radial-gradient(circle, ${alpha(theme.palette.secondary.main, 0.03)} 0%, rgba(255,255,255,0) 70%)`,
            borderRadius: '50%',
            filter: 'blur(60px)',
          }}
        />

        <Container maxWidth="lg">
          <Box sx={{ textAlign: 'center', mb: 8, position: 'relative' }}>
            <Box
              sx={{
                display: 'inline-block',
                bgcolor: alpha(theme.palette.primary.main, 0.05),
                borderRadius: '30px',
                px: 3,
                py: 1,
                mb: 3,
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 600,
                  color: theme.palette.primary.main,
                  textTransform: 'uppercase',
                  letterSpacing: 1,
                  fontSize: '0.875rem',
                }}
              >
                Our Story
              </Typography>
            </Box>
            
            <Typography
              variant="h2"
              sx={{ 
                mb: 3,
                fontWeight: 800,
                background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontSize: { xs: '2rem', md: '3rem' },
                lineHeight: 1.2,
              }}
            >
              Built by Proven Tech Founders,
              <br />
              Backed by Innovation
            </Typography>

            <Typography
              variant="h6"
              sx={{ 
                mb: 8,
                color: 'text.secondary',
                maxWidth: '800px',
                mx: 'auto',
                lineHeight: 1.6,
                fontWeight: 'normal',
              }}
            >
              Augence was founded by serial entrepreneurs with a track record of building billion-dollar enterprise software companies. Our
              mission: to bring cutting-edge AI to government and construction, transforming outdated processes into efficient digital experiences.
            </Typography>

            <Grid container spacing={4}>
              {[
                {
                  title: 'Built on Deep Tech Expertise',
                  description: 'Backed by decades of experience in AI, machine learning, and enterprise software, our team knows how to build scalable, future-ready solutions.',
                  image: tech,
                  icon: <LightbulbIcon sx={{ fontSize: 40, color: theme.palette.primary.main }} />
                },
                {
                  title: 'Proven Success at Scale',
                  description: 'Our founders have led multiple successful exits — including companies valued at over $3 billion — in the enterprise technology space.',
                  image: scale,
                  icon: <TrendingUpIcon sx={{ fontSize: 40, color: theme.palette.primary.main }} />
                },
                {
                  title: 'Driven by Purpose, Focused on Impact',
                  description: "We're on a mission to modernize government technology and simplify complex civic processes through intelligent automation.",
                  image: inpact,
                  icon: <RocketLaunchIcon sx={{ fontSize: 40, color: theme.palette.primary.main }} />
                }
              ].map((item, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                  >
                    <Paper
                      elevation={0}
                      sx={{
                        height: '100%',
                        borderRadius: '16px',
                        overflow: 'hidden',
                        position: 'relative',
                        '&:hover': {
                          '& .content': {
                            transform: 'translateY(-4px)',
                          },
                          '& img': {
                            transform: 'scale(1.05)',
                          }
                        },
                      }}
                    >
                      <Box
                        sx={{
                          height: 200,
                          position: 'relative',
                          overflow: 'hidden',
                          '& img': {
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            transition: 'transform 0.3s ease',
                          },
                        }}
                      >
                        <img src={item.image} alt={item.title} />
                        <Box
                          sx={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            height: '50%',
                            background: 'linear-gradient(to top, rgba(0,0,0,0.7), transparent)',
                          }}
                        />
                      </Box>
                      <Box
                        className="content"
                        sx={{
                          position: 'relative',
                          bgcolor: 'background.paper',
                          p: 4,
                          transition: 'transform 0.3s ease',
                        }}
                      >
                        <Box
                          sx={{
                            position: 'absolute',
                            top: -32,
                            left: 24,
                            width: 64,
                            height: 64,
                            borderRadius: '16px',
                            bgcolor: 'background.paper',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            boxShadow: theme => `0 8px 16px ${alpha(theme.palette.common.black, 0.1)}`,
                          }}
                        >
                          {item.icon}
                        </Box>
                        <Typography
                          variant="h5"
                          sx={{
                            mb: 2,
                            mt: 2,
                            fontWeight: 700,
                            color: 'text.primary',
                          }}
                        >
                          {item.title}
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{
                            color: 'text.secondary',
                            lineHeight: 1.6,
                          }}
                        >
                          {item.description}
                        </Typography>
                      </Box>
                    </Paper>
                  </motion.div>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Container>
      </Box>

      {/* Early Adopter Section */}
      <Box
        id="early-access"
        sx={{
          background: theme.palette.primary.dark,
          color: 'white',
          minHeight: '100vh',
          scrollSnapAlign: 'start',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        {/* Decorative Elements */}
        <Box
          sx={{
            position: 'absolute',
            top: '10%',
            left: '5%',
            width: '40%',
            height: '40%',
            background: 'radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)',
            borderRadius: '50%',
            filter: 'blur(40px)',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            bottom: '10%',
            right: '5%',
            width: '30%',
            height: '30%',
            background: 'radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)',
            borderRadius: '50%',
            filter: 'blur(40px)',
          }}
        />
        <Container maxWidth="md">
          <Grid container spacing={4} alignItems="center" justifyContent="center">
            <Grid item xs={12} md={6}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                <Box sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                  <Box
                    sx={{
                      display: 'inline-block',
                      bgcolor: 'rgba(255,255,255,0.1)',
                      borderRadius: '30px',
                      px: 3,
                      py: 1,
                      mb: 3,
                    }}
                  >
          <Typography
                      variant="subtitle1"
            sx={{
                        fontWeight: 600,
                        textTransform: 'uppercase',
                        letterSpacing: 1,
                        fontSize: '0.875rem',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                      }}
                    >
                      <Box component="span">🎉</Box>
                      Limited Time Offer
                    </Typography>
                  </Box>
                  <Typography 
                    variant="h2" 
                    sx={{ 
                      mb: 3,
              fontWeight: 800,
                      fontSize: { xs: '2.5rem', md: '3.5rem' },
                      lineHeight: 1.2,
                      background: 'linear-gradient(to right, #ffffff, rgba(255,255,255,0.9))',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
                    Early Adopter
                    <br />
                    Special Offer
          </Typography>
          <Typography
                    variant="h6"
            sx={{
                      mb: 6,
                      opacity: 0.9,
                      fontSize: '1.25rem',
                      lineHeight: 1.6,
                      fontWeight: 'normal',
                      maxWidth: '600px',
                    }}
                  >
                    Join now and experience our Plus plan with all premium features at no cost for 1 month. Perfect for teams looking to revolutionize their permit management process.
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', justifyContent: { xs: 'center', md: 'flex-start' } }}>
                    <Button
                      variant="contained"
                      size="large"
                      onClick={() => navigate('/signup')}
              sx={{
                        bgcolor: 'white',
                        color: theme.palette.primary.main,
                        py: 2,
                        px: 4,
                        fontSize: '1.1rem',
                        fontWeight: 600,
                        borderRadius: '30px',
                        '&:hover': {
                          bgcolor: 'rgba(255,255,255,0.9)',
                          transform: 'translateY(-2px)',
                          boxShadow: '0 6px 20px rgba(0,0,0,0.2)',
                        },
                        transition: 'all 0.3s ease',
                      }}
                    >
                      Claim Your Free Month
                    </Button>
            <Button
                      variant="outlined"
              size="large"
                      onClick={() => navigate('/pricing')}
              sx={{
                        color: 'white',
                        borderColor: 'rgba(255,255,255,0.5)',
                        py: 2,
                px: 4,
                fontSize: '1.1rem',
                        fontWeight: 600,
                        borderRadius: '30px',
                '&:hover': {
                          borderColor: 'white',
                          bgcolor: 'rgba(255,255,255,0.1)',
                          transform: 'translateY(-2px)',
                },
                        transition: 'all 0.3s ease',
              }}
            >
                      View Plans
            </Button>
          </Box>
                </Box>
              </motion.div>
            </Grid>
            <Grid item xs={12} md={6}>
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8, delay: 0.2 }}
              >
                <Box
            sx={{
                    position: 'relative',
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      background: 'rgba(255,255,255,0.1)',
                      borderRadius: '20px',
                      transform: 'rotate(-3deg)',
                    },
                  }}
                >
                  <Paper
                    elevation={0}
                    sx={{
                      position: 'relative',
                      borderRadius: '20px',
                      p: 4,
                      background: 'rgba(255,255,255,0.95)',
                      backdropFilter: 'blur(10px)',
                      border: '1px solid rgba(255,255,255,0.2)',
                      color: theme.palette.primary.main,
                    }}
                  >
                    <Box sx={{ mb: 3 }}>
                      <Typography variant="h6" sx={{ fontWeight: 600, mb: 2, color: theme.palette.primary.main }}>
                        Plus Plan Features
          </Typography>
                      {[
                        'Unlimited Project Management',
                        'AI-Powered Form Automation',
                        'Real-Time Code Compliance',
                        'Priority Support',
                        'Advanced Analytics',
                        'Team Collaboration Tools',
                      ].map((feature, index) => (
                        <Box
                          key={index}
            sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                            mb: 2,
                          }}
                        >
                          <Box
                            sx={{
                              width: 24,
                              height: 24,
                              borderRadius: '50%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              bgcolor: alpha(theme.palette.primary.main, 0.1),
                            }}
                          >
                            <CheckCircleOutlineIcon sx={{ fontSize: 16, color: theme.palette.primary.main }} />
                          </Box>
                          <Typography variant="body1" sx={{ color: 'text.primary' }}>
                            {feature}
          </Typography>
                        </Box>
                      ))}
                    </Box>
                    <Box
                      sx={{
                        p: 2,
                        borderRadius: '12px',
                        bgcolor: alpha(theme.palette.primary.main, 0.05),
                        textAlign: 'center',
                      }}
                    >
                      <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                        Regular Price
                      </Typography>
                      <Typography variant="h4" sx={{ fontWeight: 700, mb: 1, color: theme.palette.primary.main }}>
                        TBD
                        <Typography component="span" variant="body1" sx={{ color: 'text.secondary', ml: 1 }}>
                          /month
                        </Typography>
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        First month free for early adopters
                      </Typography>
                    </Box>
                  </Paper>
                </Box>
              </motion.div>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default Home; 