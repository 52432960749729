import { Permit, AIAnalysis, Project, User, Jurisdiction, ComplianceCheck, DocumentComplianceReport } from '../types';
import { getToken, setToken, removeToken } from '../utils/auth';

//const API_BASE_URL = 'http://127.0.0.1:5000';
const API_BASE_URL = 'https://augence.onrender.com';

interface APIError extends Error {
  status?: number;
  code?: string;
}

interface APIResponse<T> {
  data: T;
  message?: string;
  status: 'success' | 'error';
}

interface ChatMessage {
  role: 'user' | 'assistant';
  content: string;
}

interface ChatResponse {
  status: string;
  timestamp: string;
  message: ChatMessage;
  context?: any;
  error?: string;
}

// Helper function to handle API errors
const handleAPIError = (error: any): never => {
  // If we get a 401, clear the token
  if (error.status === 401) {
    removeToken();
  }
  
  const apiError: APIError = new Error(
    error.message || 'An unexpected error occurred'
  );
  apiError.status = error.status;
  apiError.code = error.code;
  throw apiError;
};

// Helper function to get auth headers
const getAuthHeaders = (): Record<string, string> => {
  const token = getToken();
  return token ? { 'Authorization': `Bearer ${token}` } : {};
};

// Generic API call handler
const apiCall = async <T>(
  endpoint: string,
  options: RequestInit = {}
): Promise<T> => {
  try {
    let headers: Record<string, string> = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      ...getAuthHeaders(),
      ...(options.headers as Record<string, string>),
    };

    // Remove Content-Type for FormData
    if (options.body instanceof FormData) {
      const { 'Content-Type': _, ...rest } = headers;
      headers = rest;
    }

    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
      ...options,
      headers,
    });

    if (!response.ok) {
      throw {
        status: response.status,
        message: `HTTP error! status: ${response.status}`,
      };
    }

    const data = await response.json();
    return data as T;
  } catch (error) {
    return handleAPIError(error);
  }
};

// Permit APIs
export const permitAPI = {
  // Get all permits for the current user
  getPermits: async (): Promise<APIResponse<Permit[]>> => {
    try {
      const response = await fetch(`${API_BASE_URL}/permits`, {
        headers: {
          'Authorization': `Bearer ${getToken()}`
        }
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching permits:', error);
      return { status: 'error', message: 'Failed to fetch permits', data: [] };
    }
  },

  // Get a specific permit by ID
  getPermit: (permitId: string) => {
    return apiCall<APIResponse<Permit>>(`/permits/${permitId}`, {
      method: 'GET',
    });
  },

  // Create a new permit
  createPermit: async (permitData: any, files: File[]): Promise<APIResponse<Permit>> => {
    try {
      const formData = new FormData();
      
      // Append permit data as JSON string to ensure proper parsing on server
      formData.append('data', JSON.stringify({
        permitType: permitData.permitType,
        estimatedTimeline: permitData.estimatedTimeline,
        projectID: permitData.projectID
      }));

      // Append files
      files.forEach(file => {
        formData.append('documents', file);
      });

      const response = await fetch(`${API_BASE_URL}/permits`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${getToken()}`
        },
        body: formData
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        throw new Error(errorData?.message || `HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error creating permit:', error);
      return { 
        status: 'error', 
        message: error instanceof Error ? error.message : 'Failed to create permit',
        data: {} as Permit // Fix for TypeScript error
      };
    }
  },

  // Update an existing permit
  updatePermit: (permitId: string, permitData: Partial<Permit>) => {
    return apiCall<APIResponse<Permit>>(`/permits/${permitId}`, {
      method: 'PUT',
      body: JSON.stringify(permitData),
    });
  },

  // Delete a permit
  deletePermit: (permitId: string) => {
    return apiCall<APIResponse<void>>(`/permits/${permitId}`, {
      method: 'DELETE',
    });
  },

  // Analyze permit documents
  analyzePermit: (permitId: string, files: File[]) => {
    const formData = new FormData();
    formData.append('permitId', permitId);
    
    files.forEach(file => {
      formData.append('files[]', file);
    });

    return apiCall<APIResponse<AIAnalysis>>('/permits/analyze', {
      method: 'POST',
      body: formData,
      headers: {} // Let browser set the Content-Type header for FormData
    });
  },

  // Get permit analysis report
  getPermitReport: (permitId: string, checkId?: string) => {
    if (checkId) {
      // Get specific compliance check report
      return apiCall<APIResponse<ComplianceCheck>>(`/permits/${permitId}/compliance-checks/${checkId}`, {
        method: 'GET',
      });
    } else {
      // Get general permit report
      return apiCall<APIResponse<AIAnalysis>>(`/permits/${permitId}/report`, {
        method: 'GET',
      });
    }
  },

  getDocument: (permitId: string, documentId: string) => {
    return apiCall<APIResponse<{
      documentID: string;
      name: string;
      type: string;
      uploadDate: string;
      version: number;
      downloadUrl: string;
    }>>(`/permits/${permitId}/documents/${documentId}`, {
      method: 'GET',
    });
  },

  // Get all compliance checks for a permit
  getPermitComplianceChecks: (permitId: string) => {
    return apiCall<APIResponse<ComplianceCheck[]>>(`/permits/${permitId}/compliance-checks`, {
      method: 'GET',
    });
  },
};

// Define new types for the compliance service response
interface ComplianceIssue {
  id: string;
  severity: 'high' | 'medium' | 'low';
  category: string;
  description: string;
  code_reference: string;
  remediation: string;
  estimated_effort: string;
  document_name: string;
}

interface ComplianceAnalysis {
  summary: string;
  risk_level: string;
  total_issues: number;
  issues: ComplianceIssue[];
  recommendations: string[];
}

interface PermitAnalysis {
  permit_id: string;
  permit_type: string;
  status: string;
  documents_analyzed: number;
  check_id: string | null;
  analysis_results: {
    analysis: ComplianceAnalysis;
  };
  statistics: {
    total_issues: number;
    issues_by_severity: {
      high: number;
      medium: number;
      low: number;
    };
  };
}

interface ProjectComplianceResponse {
  project_id: string;
  project_name: string;
  jurisdiction: string;
  code_version: string;
  timestamp: string;
  permit_analyses: PermitAnalysis[];
  total_permits_analyzed: number;
  total_documents_analyzed: number;
  overall_risk_level: string;
}

// Project APIs
export const projectAPI = {
  // Get all projects
  getProjects: async (): Promise<APIResponse<Project[]>> => {
    return apiCall<APIResponse<Project[]>>('/projects', {
      method: 'GET'
    });
  },

  // Get a specific project
  getProject: (projectId: string): Promise<APIResponse<Project>> => {
    return apiCall<APIResponse<Project>>(`/projects/${projectId}`, {
      method: 'GET'
    });
  },

  // Get project compliance checks
  getProjectComplianceChecks: (projectId: string) => {
    return apiCall<APIResponse<ComplianceCheck[]>>(`/projects/${projectId}/compliance-checks`, {
      method: 'GET',
    });
  },

  // Run compliance check for a project
  runComplianceCheck: (projectId: string) => {
    const token = getToken();
    if (!token) {
      throw new Error('No authentication token found');
    }
    return apiCall<APIResponse<ProjectComplianceResponse>>(`/projects/${projectId}/compliance-check`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
  },

  // Create a new project
  createProject: (projectData: Partial<Project>) => {
    const token = getToken();
    if (!token) {
      throw new Error('No authentication token found');
    }
    return apiCall<APIResponse<Project>>('/projects', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(projectData),
    });
  },

  // Update an existing project
  updateProject: (projectId: string, projectData: Partial<Project>) => {
    const token = getToken();
    if (!token) {
      throw new Error('No authentication token found');
    }
    return apiCall<APIResponse<Project>>(`/projects/${projectId}`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(projectData),
    });
  },

  // Delete a project
  deleteProject: (projectId: string) => {
    const token = getToken();
    if (!token) {
      throw new Error('No authentication token found');
    }
    return apiCall<APIResponse<void>>(`/projects/${projectId}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
  },
};

// Document APIs
export const documentAPI = {
  // Upload a document
  uploadDocument: (permitId: string, file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('permitId', permitId);

    return apiCall<APIResponse<{ documentId: string }>>('/documents/upload', {
      method: 'POST',
      body: formData,
      headers: {} // Let browser set the Content-Type header for FormData
    });
  },

  // Delete a document
  deleteDocument: (documentId: string) => {
    return apiCall<APIResponse<void>>(`/documents/${documentId}`, {
      method: 'DELETE',
    });
  },

  // Get document analysis
  getDocumentAnalysis: (documentId: string) => {
    return apiCall<APIResponse<AIAnalysis>>(`/documents/${documentId}/analysis`, {
      method: 'GET',
    });
  },
};

// Document Compliance APIs
export const documentComplianceAPI = {
  // Analyze document compliance
  analyzeDocumentCompliance: (permitId: string, documentId: string) => {
    return apiCall<APIResponse<{ report: DocumentComplianceReport }>>('/api/v1/compliance/analyze/document', {
      method: 'POST',
      body: JSON.stringify({
        permitId,
        documentId
      })
    });
  },

  // Get document compliance report
  getDocumentComplianceReport: (permitId: string, documentId: string) => {
    return apiCall<APIResponse<DocumentComplianceReport>>(`/api/v1/compliance/reports/permit/${permitId}/document/${documentId}`, {
      method: 'GET'
    });
  }
};

// Auth APIs
export const authAPI = {
  // Login with email and password
  login: async (credentials: { email: string; password: string }) => {
    const response = await apiCall<APIResponse<{ token: string }>>('/auth/login', {
      method: 'POST',
      body: JSON.stringify(credentials),
    });
    
    if (response.status === 'success' && response.data.token) {
      setToken(response.data.token);
      localStorage.setItem('isAuthenticated', 'true');
    }
    
    return response;
  },

  // Login with Google
  loginWithGoogle: () => {
    window.location.href = `${API_BASE_URL}/auth/google`;
  },

  // Login with Microsoft
  loginWithMicrosoft: () => {
    window.location.href = `${API_BASE_URL}/auth/microsoft`;
  },

  // Logout
  logout: async () => {
    try {
      const response = await apiCall<APIResponse<void>>('/auth/logout', {
        method: 'POST',
      });
      removeToken();
      localStorage.removeItem('isAuthenticated');
      return response;
    } catch (error) {
      removeToken();
      localStorage.removeItem('isAuthenticated');
      throw error;
    }
  },

  // Check authentication status
  checkAuth: () => {
    return apiCall<APIResponse<{ isAuthenticated: boolean }>>('/auth/status', {
      method: 'GET',
    });
  },

  // Request password reset
  requestPasswordReset: (email: string) => {
    return apiCall<APIResponse<void>>('/auth/reset-password', {
      method: 'POST',
      body: JSON.stringify({ email }),
    });
  },

  // Reset password with token
  resetPassword: (token: string, newPassword: string) => {
    return apiCall<APIResponse<void>>('/auth/reset-password', {
      method: 'PUT',
      body: JSON.stringify({ token, newPassword }),
    });
  },

  // Sign up with email and password
  signup: async (data: Omit<User, 'userID'> & { password: string; marketingConsent: boolean }) => {
    const response = await apiCall<APIResponse<{ token: string }>>('/auth/signup', {
      method: 'POST',
      body: JSON.stringify(data),
    });
    
    if (response.status === 'success' && response.data.token) {
      setToken(response.data.token);
      localStorage.setItem('isAuthenticated', 'true');
    }
    
    return response;
  },
};

// Jurisdiction APIs
export const jurisdictionAPI = {
  // Get all jurisdictions
  getJurisdictions: () => {
    return apiCall<APIResponse<Jurisdiction[]>>('/jurisdictions', {
      method: 'GET',
    });
  },
};

// Chat APIs
export const chatAPI = {
  sendMessage: async (message: string) => {
    return apiCall<ChatResponse>('/chat/message', {
      method: 'POST',
      body: JSON.stringify({ 
        messages: [
          {
            role: 'user',
            content: message
          }
        ]
      }),
    });
  },
};

// Export all APIs
export default {
  permit: permitAPI,
  project: projectAPI,
  document: documentAPI,
  auth: authAPI,
}; 
