import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Paper,
  Button,
  IconButton,
  useTheme,
  alpha,
  Grid,
  Divider,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Alert,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { styled } from '@mui/material/styles';
import DownloadIcon from '@mui/icons-material/Download';
import RefreshIcon from '@mui/icons-material/Refresh';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import DescriptionIcon from '@mui/icons-material/Description';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import WarningIcon from '@mui/icons-material/Warning';
import { DocumentComplianceReport as DocumentComplianceReportType } from '../types';
import { documentComplianceAPI, permitAPI } from '../apis';

// Styled Components
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: 16,
  boxShadow: '0 4px 20px rgba(0,0,0,0.05)',
  background: theme.palette.background.paper,
  display: 'flex',
  flexDirection: 'column',
}));

const StatusChip = styled(Box)<{ status: 'Pass' | 'Fail' | 'Partial Pass' }>(({ theme, status }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  padding: '4px 12px',
  borderRadius: '100px',
  fontSize: '0.875rem',
  fontWeight: 500,
  gap: '6px',
  whiteSpace: 'nowrap',
  maxWidth: '100%',
  ...(status === 'Pass' && {
    backgroundColor: '#8BC34A',
    color: '#fff',
  }),
  ...(status === 'Fail' && {
    backgroundColor: '#F44336',
    color: '#fff',
  }),
  ...(status === 'Partial Pass' && {
    backgroundColor: '#FFC107',
    color: '#fff',
  }),
}));

const StatCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: 12,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
  width: 'auto',
  flex: '1 1 0',
  minWidth: 120,
}));

const FractionText = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  '& .divider': {
    width: '0.8em',
    height: '2px',
    backgroundColor: 'currentColor',
    margin: '0 2px',
  },
}));

const TableHeader = styled(Box)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.main, 0.04),
  borderRadius: '8px 8px 0 0',
  padding: theme.spacing(1.5, 2),
}));

const TableRow = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.02),
  },
}));

const GradientButton = styled(Button)(({ theme }) => ({
  borderRadius: '100px',
  padding: '6px 24px',
  height: '36px',
  fontSize: '14px',
  textTransform: 'none',
  boxShadow: 'none',
  whiteSpace: 'nowrap',
  display: 'inline-flex',
  alignItems: 'center',
  '&:hover': {
    boxShadow: 'none',
  },
  '& .MuiButton-startIcon': {
    marginRight: '10px',
    marginLeft: '-4px',
    '& > *:first-of-type': {
      fontSize: '18px',
    },
  },
}));

const DownloadButton = styled(GradientButton)({
  backgroundColor: '#2B3147',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#363D57',
  },
});

const RecheckButton = styled(GradientButton)({
  background: 'linear-gradient(90deg, #4CC9F0 0%, #B829E9 100%)',
  color: '#fff',
  '&:hover': {
    background: 'linear-gradient(90deg, #44B4D7 0%, #A424D0 100%)',
  },
});

// Add styled component for filter buttons
const FilterToggleButton = styled(ToggleButton)(({ theme }) => ({
  borderRadius: '100px',
  padding: '6px 16px',
  border: 'none',
  fontSize: '14px',
  textTransform: 'none',
  '&.MuiToggleButton-root': {
    border: 'none',
    marginRight: '8px',
    '&:last-child': {
      marginRight: 0,
    },
  },
  '&.Mui-selected': {
    backgroundColor: '#fff',
    boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
}));

const FilterToggleGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  gap: '8px',
  '& .MuiToggleButtonGroup-grouped': {
    margin: 0,
    border: 0,
    '&:not(:first-of-type)': {
      borderRadius: '100px',
    },
    '&:first-of-type': {
      borderRadius: '100px',
    },
  },
}));

const DocumentComplianceReport: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { documentId } = useParams<{ documentId: string }>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [report, setReport] = useState<DocumentComplianceReportType | null>(null);
  const [isRerunningAnalysis, setIsRerunningAnalysis] = useState(false);
  const [statusFilter, setStatusFilter] = useState<string[]>([]);

  useEffect(() => {
    const fetchReport = async () => {
      if (!documentId) return;

      try {
        setLoading(true);
        setError(null);
        
        // First get the document details to get the permitId
        const permits = await permitAPI.getPermits();
        if (permits.status !== 'success') {
          throw new Error('Failed to fetch permits');
        }

        // Find the permit containing our document
        let permitId: string | null = null;
        for (const permit of permits.data) {
          const doc = permit.documents?.find(d => d.documentID === documentId);
          if (doc) {
            permitId = permit.permitID;
            break;
          }
        }

        if (!permitId) {
          throw new Error('Document not found in any permit');
        }
        
        // Now get the compliance report
        const reportResponse = await documentComplianceAPI.getDocumentComplianceReport(permitId, documentId);
        
        console.log('Document Compliance Report API Response:', {
          status: reportResponse.status,
          message: reportResponse.message,
          data: reportResponse.data
        });
        
        if (reportResponse.status === 'success' && reportResponse.data) {
          setReport(reportResponse.data);
        } else {
          throw new Error(reportResponse.message || 'Failed to fetch compliance report');
        }
      } catch (error) {
        const errorDetails = {
          error,
          documentId,
          errorMessage: error instanceof Error ? error.message : 'Unknown error'
        };
        console.error('Error fetching compliance report:', errorDetails);
        setError('Failed to load compliance report. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchReport();
  }, [documentId]);

  const handleRerunAnalysis = async () => {
    if (!documentId || !report) return;

    try {
      setIsRerunningAnalysis(true);
      setError(null);
      
      const response = await documentComplianceAPI.analyzeDocumentCompliance(report.permit_id, documentId);
      
      if (response.status === 'success' && response.data) {
        setReport(response.data.report);
      } else {
        throw new Error(response.message || 'Failed to rerun analysis');
      }
    } catch (error) {
      console.error('Error rerunning analysis:', error);
      setError('Failed to rerun analysis. Please try again later.');
    } finally {
      setIsRerunningAnalysis(false);
    }
  };

  const handleStatusFilterChange = (
    event: React.MouseEvent<HTMLElement>,
    newFilters: string[]
  ) => {
    setStatusFilter(newFilters);
  };

  const filteredChecks = (report?.checks || []).filter(check => 
    statusFilter.length === 0 || statusFilter.includes(check.status)
  );

  if (loading) {
    return (
      <Container maxWidth="xl" sx={{ py: 4, mt: { xs: 5, sm: 6 }, px: { xs: 2, sm: 3 } }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '60vh' }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error || !report) {
    return (
      <Container maxWidth="xl" sx={{ py: 4, mt: { xs: 5, sm: 6 }, px: { xs: 2, sm: 3 } }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
          <Alert severity="error" sx={{ width: '100%', maxWidth: 600 }}>
            {error || 'Report not found'}
          </Alert>
          <Button variant="contained" onClick={() => navigate(-1)}>
            Go Back
          </Button>
        </Box>
      </Container>
    );
  }

  const getStatusIcon = (status: 'Pass' | 'Fail' | 'Partial Pass') => {
    switch (status) {
      case 'Pass':
        return <CheckCircleIcon sx={{ fontSize: 16 }} />;
      case 'Fail':
        return <CancelIcon sx={{ fontSize: 16 }} />;
      case 'Partial Pass':
        return <WarningIcon sx={{ fontSize: 16 }} />;
    }
  };

  return (
    <Container 
      maxWidth="xl" 
      sx={{ 
        py: 4,
        mt: { xs: 5, sm: 6 },
        px: { xs: 2, sm: 3 }
      }}
    >
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
      >
        {/* Header */}
        <Box sx={{ mb: 4, display: 'flex', alignItems: 'center' }}>
          <IconButton
            onClick={() => navigate(-1)}
            sx={{ mr: 2 }}
            aria-label="back"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4" component="h1">
            Compliance Check Report for "{report.document_name}" ({report.version})
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <DownloadButton
            startIcon={<DownloadIcon />}
            sx={{ mr: 2 }}
          >
            Download
          </DownloadButton>
          <RecheckButton
            startIcon={isRerunningAnalysis ? <CircularProgress size={20} color="inherit" /> : <RefreshIcon />}
            onClick={handleRerunAnalysis}
            disabled={isRerunningAnalysis}
          >
            {isRerunningAnalysis ? 'Checking...' : 'Recheck'}
          </RecheckButton>
        </Box>

        <Grid container spacing={3} sx={{ maxWidth: '100%' }}>
          {/* Left Column */}
          <Grid item xs={12} lg={6}>
            {/* Summary Stats Cards */}
            <Box sx={{ 
              mb: 3, 
              display: 'flex', 
              gap: 2,
              width: '100%'
            }}>
              <StatCard elevation={0}>
                <Typography variant="h3" sx={{ mb: 1, fontWeight: 500 }}>
                  {report.total_checks}
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                  Total Checks
                </Typography>
              </StatCard>

              <StatCard 
                elevation={0} 
                sx={{ color: theme.palette.success.main }}
              >
                <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.5, mb: 1 }}>
                  <Typography variant="h3" sx={{ fontWeight: 500 }}>
                    {report.passed_checks}
                  </Typography>
                  <Typography variant="h6" sx={{ opacity: 0.7 }}>
                    /{report.total_checks}
                  </Typography>
                </Box>
                <Typography variant="subtitle2" color="inherit" sx={{ opacity: 0.8 }}>
                  Pass
                </Typography>
              </StatCard>

              <StatCard 
                elevation={0} 
                sx={{ color: theme.palette.error.main }}
              >
                <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.5, mb: 1 }}>
                  <Typography variant="h3" sx={{ fontWeight: 500 }}>
                    {report.failed_checks}
                  </Typography>
                  <Typography variant="h6" sx={{ opacity: 0.7 }}>
                    /{report.total_checks}
                  </Typography>
                </Box>
                <Typography variant="subtitle2" color="inherit" sx={{ opacity: 0.8 }}>
                  Fail
                </Typography>
              </StatCard>

              <StatCard 
                elevation={0} 
                sx={{ color: theme.palette.warning.main }}
              >
                <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.5, mb: 1 }}>
                  <Typography variant="h3" sx={{ fontWeight: 500 }}>
                    {report.partial_checks}
                  </Typography>
                  <Typography variant="h6" sx={{ opacity: 0.7 }}>
                    /{report.total_checks}
                  </Typography>
                </Box>
                <Typography variant="subtitle2" color="inherit" sx={{ opacity: 0.8 }}>
                  Partial Pass
                </Typography>
              </StatCard>
            </Box>

            {/* Overview Section */}
            <StyledPaper sx={{ mb: 3 }}>
              <Typography variant="h6" gutterBottom>Overview</Typography>
              <Box sx={{ '& > div': { mb: 2 } }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <CalendarTodayIcon color="action" />
                  <Box>
                    <Typography color="text.secondary" variant="body2">Check Date</Typography>
                    <Typography variant="body1">{new Date(report.created_at).toLocaleDateString()}</Typography>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <LocationCityIcon color="action" />
                  <Box>
                    <Typography color="text.secondary" variant="body2">Jurisdiction</Typography>
                    <Typography variant="body1">{report.jurisdiction}</Typography>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <DescriptionIcon color="action" />
                  <Box>
                    <Typography color="text.secondary" variant="body2">Checked Against City Code Version</Typography>
                    <Typography variant="body1">{report.code_version}</Typography>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <ErrorOutlineIcon color="action" />
                  <Box>
                    <Typography color="text.secondary" variant="body2">Submission Status</Typography>
                    <StatusChip status={report.submission_status === "Ready" ? "Pass" : "Fail"}>
                      {report.submission_status}
                    </StatusChip>
                  </Box>
                </Box>
              </Box>
            </StyledPaper>

            {/* Additional Comments Section */}
            <StyledPaper>
              <Typography variant="h6" gutterBottom>Additional Comments</Typography>
              <Box sx={{ 
                bgcolor: alpha(theme.palette.warning.main, 0.05), 
                p: 2, 
                borderRadius: 1,
                border: `1px solid ${alpha(theme.palette.warning.main, 0.1)}`
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                  <WarningIcon color="warning" />
                  <Typography variant="subtitle2" color="warning.main">
                    Critical Issues Found:
                  </Typography>
                </Box>
                <List dense sx={{ mt: 1 }}>
                  {(report.critical_issues || []).map((issue, index) => (
                    <ListItem key={index} sx={{ pl: 0 }}>
                      <ListItemText 
                        primary={issue}
                        primaryTypographyProps={{
                          variant: 'body2',
                          color: 'text.secondary',
                          sx: { lineHeight: 1.5 }
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            </StyledPaper>
          </Grid>

          {/* Right Column - Compliance Checks Table */}
          <Grid item xs={12} lg={6}>
            <StyledPaper>
              <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6">Compliance Checks</Typography>
                <FilterToggleGroup
                  value={statusFilter}
                  onChange={handleStatusFilterChange}
                  aria-label="status filter"
                  exclusive={false}
                  sx={{ backgroundColor: alpha(theme.palette.primary.main, 0.04), p: 0.5, borderRadius: '100px' }}
                >
                  <FilterToggleButton value="Pass" aria-label="show passed">
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box sx={{ width: 8, height: 8, borderRadius: '50%', backgroundColor: '#8BC34A' }} />
                      Pass
                    </Box>
                  </FilterToggleButton>
                  <FilterToggleButton value="Fail" aria-label="show failed">
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box sx={{ width: 8, height: 8, borderRadius: '50%', backgroundColor: '#F44336' }} />
                      Fail
                    </Box>
                  </FilterToggleButton>
                  <FilterToggleButton value="Partial Pass" aria-label="show partial pass">
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box sx={{ width: 8, height: 8, borderRadius: '50%', backgroundColor: '#FFC107' }} />
                      Partial
                    </Box>
                  </FilterToggleButton>
                </FilterToggleGroup>
              </Box>
              <Box>
                <TableHeader>
                  <Grid container>
                    <Grid item xs={4}>
                      <Typography variant="subtitle2" fontWeight={600}>Check Performed</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="subtitle2" fontWeight={600}>Result</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography variant="subtitle2" fontWeight={600}>Required Action / Notes</Typography>
                    </Grid>
                  </Grid>
                </TableHeader>
                <Box>
                  {filteredChecks.map((check, index) => (
                    <React.Fragment key={index}>
                      <TableRow>
                        <Grid container alignItems="flex-start" spacing={1}>
                          <Grid item xs={4}>
                            <Typography variant="body2">{check.checkName}</Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Box sx={{ minWidth: 0, display: 'flex', alignItems: 'center' }}>
                              <StatusChip status={check.status}>
                                {getStatusIcon(check.status)}
                              </StatusChip>
                            </Box>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography variant="body2" color="text.secondary" sx={{ lineHeight: 1.5 }}>
                              {check.notes}
                            </Typography>
                          </Grid>
                        </Grid>
                      </TableRow>
                      {index < (report.checks || []).length - 1 && <Divider />}
                    </React.Fragment>
                  ))}
                </Box>
              </Box>
            </StyledPaper>
          </Grid>
        </Grid>
      </motion.div>
    </Container>
  );
};

export default DocumentComplianceReport; 