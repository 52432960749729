import React from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  useTheme,
  alpha,
  Divider,
} from '@mui/material';
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Warning as WarningIcon,
  CalendarToday as CalendarTodayIcon,
  LocationCity as LocationCityIcon,
  Description as DescriptionIcon,
  ErrorOutline as ErrorOutlineIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';

// Styled Components
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: 16,
  boxShadow: '0 4px 20px rgba(0,0,0,0.05)',
  background: theme.palette.background.paper,
  display: 'flex',
  flexDirection: 'column',
}));

const StatusChip = styled(Box)<{ status: 'Pass' | 'Fail' | 'Partial' }>(({ theme, status }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  padding: '4px 12px',
  borderRadius: '100px',
  fontSize: '0.875rem',
  fontWeight: 500,
  gap: '6px',
  whiteSpace: 'nowrap',
  maxWidth: '100%',
  ...(status === 'Pass' && {
    backgroundColor: '#8BC34A',
    color: '#fff',
  }),
  ...(status === 'Fail' && {
    backgroundColor: '#F44336',
    color: '#fff',
  }),
  ...(status === 'Partial' && {
    backgroundColor: '#FFC107',
    color: '#fff',
  }),
}));

const StatCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: 12,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
  width: 'auto',
  flex: '1 1 0',
  minWidth: 120,
}));

const TableHeader = styled(Box)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.main, 0.04),
  borderRadius: '8px 8px 0 0',
  padding: theme.spacing(1.5, 2),
}));

const TableRow = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.02),
  },
}));

const DocumentCompliancePreview: React.FC = () => {
  const theme = useTheme();

  // Sample data matching DocumentComplianceReport structure
  const sampleReport = {
    document_name: "Building Plans - Second Floor",
    version: "1.0",
    total_checks: 24,
    passed_checks: 15,
    failed_checks: 5,
    partial_checks: 4,
    created_at: new Date().toISOString(),
    jurisdiction: "San Francisco, CA",
    code_version: "SF Building Code 2025",
    submission_status: "In Review",
    critical_issues: [
      "Emergency egress path width does not meet minimum requirements",
      "Fire safety compliance issues in multiple sections",
      "Seismic resistance calculations need revision"
    ],
    checks: [
      {
        checkName: "Emergency Exit Width",
        status: "Fail" as const,
        notes: "Exit width is 36 inches, below required 48 inches minimum for commercial spaces"
      },
      {
        checkName: "Fire Safety Systems",
        status: "Partial" as const,
        notes: "Sprinkler system layout acceptable, but additional coverage needed in storage areas"
      },
      {
        checkName: "Structural Load",
        status: "Pass" as const,
        notes: "All load-bearing calculations within acceptable ranges"
      },
      {
        checkName: "Seismic Requirements",
        status: "Fail" as const,
        notes: "Lateral force-resisting system needs reinforcement to meet SF seismic code"
      },
      {
        checkName: "ADA Compliance",
        status: "Pass" as const,
        notes: "All accessibility requirements met for commercial space"
      },
      {
        checkName: "Energy Code",
        status: "Pass" as const,
        notes: "Meets Title 24 energy efficiency standards"
      },
      {
        checkName: "Ventilation Systems",
        status: "Partial" as const,
        notes: "Primary ventilation adequate, but additional exhaust needed in utility rooms"
      },
      {
        checkName: "Floor Area Ratio",
        status: "Pass" as const,
        notes: "Within permitted FAR limits for zoning district"
      },
      {
        checkName: "Height and Bulk",
        status: "Pass" as const,
        notes: "Complies with SF Planning Code height restrictions"
      },
      {
        checkName: "Fire Resistance",
        status: "Fail" as const,
        notes: "Wall assemblies need upgrade to achieve required 2-hour rating"
      },
      {
        checkName: "Means of Egress",
        status: "Partial" as const,
        notes: "Exit signs and emergency lighting compliant, but additional exit required"
      },
      {
        checkName: "Plumbing Fixtures",
        status: "Pass" as const,
        notes: "Fixture count meets occupancy requirements"
      }
    ]
  };

  const getStatusIcon = (status: 'Pass' | 'Fail' | 'Partial') => {
    switch (status) {
      case 'Pass':
        return <CheckCircleIcon sx={{ fontSize: 16 }} />;
      case 'Fail':
        return <CancelIcon sx={{ fontSize: 16 }} />;
      case 'Partial':
        return <WarningIcon sx={{ fontSize: 16 }} />;
    }
  };

  return (
    <Box sx={{ p: { xs: 2, md: 3 } }}>
      <Grid container spacing={3}>
        {/* Left Column - Stats and Overview */}
        <Grid item xs={12} lg={6}>
          {/* Stats Cards */}
          <Box sx={{ 
            mb: 3, 
            display: 'flex',
            gap: 2,
            width: '100%'
          }}>
            <StatCard 
              elevation={0}
              sx={{ 
                minWidth: '100px',
                flex: 1,
                p: 1.5
              }}
            >
              <Typography variant="h4" sx={{ mb: 0.5, fontWeight: 500 }}>
                {sampleReport.total_checks}
              </Typography>
              <Typography variant="subtitle2" color="text.secondary">
                Total Checks
              </Typography>
            </StatCard>

            <StatCard 
              elevation={0} 
              sx={{ 
                color: theme.palette.success.main,
                minWidth: '100px',
                flex: 1,
                p: 1.5
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.5, mb: 0.5 }}>
                <Typography variant="h4" sx={{ fontWeight: 500 }}>
                  {sampleReport.passed_checks}
                </Typography>
                <Typography variant="subtitle1" sx={{ opacity: 0.7 }}>
                  /{sampleReport.total_checks}
                </Typography>
              </Box>
              <Typography variant="subtitle2" color="inherit" sx={{ opacity: 0.8 }}>
                Pass
              </Typography>
            </StatCard>

            <StatCard 
              elevation={0} 
              sx={{ 
                color: theme.palette.error.main,
                minWidth: '100px',
                flex: 1,
                p: 1.5
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.5, mb: 0.5 }}>
                <Typography variant="h4" sx={{ fontWeight: 500 }}>
                  {sampleReport.failed_checks}
                </Typography>
                <Typography variant="subtitle1" sx={{ opacity: 0.7 }}>
                  /{sampleReport.total_checks}
                </Typography>
              </Box>
              <Typography variant="subtitle2" color="inherit" sx={{ opacity: 0.8 }}>
                Fail
              </Typography>
            </StatCard>

            <StatCard 
              elevation={0} 
              sx={{ 
                color: theme.palette.warning.main,
                minWidth: '100px',
                flex: 1,
                p: 1.5
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.5, mb: 0.5 }}>
                <Typography variant="h4" sx={{ fontWeight: 500 }}>
                  {sampleReport.partial_checks}
                </Typography>
                <Typography variant="subtitle1" sx={{ opacity: 0.7 }}>
                  /{sampleReport.total_checks}
                </Typography>
              </Box>
              <Typography variant="subtitle2" color="inherit" sx={{ opacity: 0.8 }}>
                Partial
              </Typography>
            </StatCard>
          </Box>

          {/* Overview Section */}
          <StyledPaper sx={{ mb: 3 }}>
            <Typography variant="h6" gutterBottom>Overview</Typography>
            <Box sx={{ '& > div': { mb: 2 } }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <CalendarTodayIcon color="action" />
                <Box>
                  <Typography color="text.secondary" variant="body2">Check Date</Typography>
                  <Typography variant="body1">{new Date(sampleReport.created_at).toLocaleDateString()}</Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <LocationCityIcon color="action" />
                <Box>
                  <Typography color="text.secondary" variant="body2">Jurisdiction</Typography>
                  <Typography variant="body1">{sampleReport.jurisdiction}</Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <DescriptionIcon color="action" />
                <Box>
                  <Typography color="text.secondary" variant="body2">Code Version</Typography>
                  <Typography variant="body1">{sampleReport.code_version}</Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <ErrorOutlineIcon color="action" />
                <Box>
                  <Typography color="text.secondary" variant="body2">Submission Status</Typography>
                  <StatusChip status={sampleReport.submission_status === "Ready" ? "Pass" : "Fail"}>
                    {sampleReport.submission_status}
                  </StatusChip>
                </Box>
              </Box>
            </Box>
          </StyledPaper>

          {/* Additional Comments Section */}
          <StyledPaper>
            <Typography variant="h6" gutterBottom>Additional Comments</Typography>
            <Box sx={{ 
              bgcolor: alpha(theme.palette.warning.main, 0.05), 
              p: 2, 
              borderRadius: 1,
              border: `1px solid ${alpha(theme.palette.warning.main, 0.1)}`
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                <WarningIcon color="warning" />
                <Typography variant="subtitle2" color="warning.main">
                  Critical Issues Found:
                </Typography>
              </Box>
              <Box component="ul" sx={{ mt: 1, pl: 2, mb: 0 }}>
                {sampleReport.critical_issues.map((issue, index) => (
                  <Box 
                    component="li" 
                    key={index}
                    sx={{ 
                      color: 'text.secondary',
                      fontSize: '0.875rem',
                      lineHeight: 1.5,
                      mb: 1,
                      '&:last-child': { mb: 0 }
                    }}
                  >
                    {issue}
                  </Box>
                ))}
              </Box>
            </Box>
          </StyledPaper>
        </Grid>

        {/* Right Column - Compliance Checks */}
        <Grid item xs={12} lg={6}>
          <StyledPaper sx={{ 
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
          }}>
            <Typography variant="h6" gutterBottom>Recent Compliance Checks</Typography>
            <Box sx={{ 
              flex: 1,
              display: 'flex',
              flexDirection: 'column'
            }}>
              <TableHeader>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography variant="subtitle2" fontWeight={600}>Check Performed</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="subtitle2" fontWeight={600}>Result</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="subtitle2" fontWeight={600}>Required Action / Notes</Typography>
                  </Grid>
                </Grid>
              </TableHeader>
              <Box sx={{ flex: 1 }}>
                {sampleReport.checks.map((check, index) => (
                  <React.Fragment key={index}>
                    <TableRow>
                      <Grid container alignItems="flex-start" spacing={1}>
                        <Grid item xs={4}>
                          <Typography variant="body2">{check.checkName}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Box sx={{ minWidth: 0, display: 'flex', alignItems: 'center' }}>
                            <StatusChip status={check.status}>
                              {getStatusIcon(check.status)}
                              {check.status}
                            </StatusChip>
                          </Box>
                        </Grid>
                        <Grid item xs={5}>
                          <Typography variant="body2" color="text.secondary" sx={{ lineHeight: 1.5 }}>
                            {check.notes}
                          </Typography>
                        </Grid>
                      </Grid>
                    </TableRow>
                    {index < sampleReport.checks.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </Box>
            </Box>
          </StyledPaper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DocumentCompliancePreview; 
