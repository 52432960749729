import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Paper,
  Grid,
  Chip,
  useTheme,
  alpha,
  CircularProgress,
  IconButton,
  Button,
  Alert,
} from '@mui/material';
import {
  ArrowBack as ArrowBackIcon,
  Download as DownloadIcon,
  CheckCircle as CheckCircleIcon,
  Warning as WarningIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
} from '@mui/icons-material';
import { useParams, useNavigate } from 'react-router-dom';
import { ComplianceCheck, Permit, Jurisdiction } from '../types';
import { permitAPI, projectAPI, jurisdictionAPI } from '../apis';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

// Helper function to get status chip
const getStatusChip = (status: string) => {
  const config = {
    approved: { color: 'success' as const, label: 'Approved' },
    rejected: { color: 'error' as const, label: 'Rejected' },
    in_review: { color: 'warning' as const, label: 'In Review' },
    pending: { color: 'default' as const, label: 'Pending' }
  };
  const statusConfig = config[status as keyof typeof config] || config.pending;
  return (
    <Chip
      label={statusConfig.label}
      color={statusConfig.color}
      size="small"
      sx={{ minWidth: 85 }}
    />
  );
};

const ComplianceReport: React.FC = () => {
  const theme = useTheme();
  const { permitId } = useParams<{ permitId: string }>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [permit, setPermit] = useState<Permit | null>(null);
  const [jurisdiction, setJurisdiction] = useState<Jurisdiction | null>(null);
  const [complianceCheck, setComplianceCheck] = useState<ComplianceCheck | null>(null);
  const [generatingPdf, setGeneratingPdf] = useState(false);

  useEffect(() => {
    const fetchReport = async () => {
      if (!permitId) return;

      try {
        setLoading(true);
        setError(null);

        // Get permit details first
        const permitResponse = await permitAPI.getPermit(permitId);
        if (permitResponse.status !== 'success' || !permitResponse.data) {
          throw new Error('Failed to fetch permit details');
        }
        setPermit(permitResponse.data);

        // Get project details to get jurisdiction
        const projectResponse = await projectAPI.getProject(permitResponse.data.projectID);
        if (projectResponse.status === 'success' && projectResponse.data) {
          // Get jurisdiction details
          const jurisdictionResponse = await jurisdictionAPI.getJurisdictions();
          if (jurisdictionResponse.status === 'success' && jurisdictionResponse.data) {
            const jurisdictionData = jurisdictionResponse.data.find(
              j => j.jurisdictionID === projectResponse.data.jurisdictionID
            );
            if (jurisdictionData) {
              setJurisdiction(jurisdictionData);
            }
          }

          // Get compliance checks for this project
          const checksResponse = await projectAPI.getProjectComplianceChecks(projectResponse.data.projectID);
          if (checksResponse.status === 'success' && checksResponse.data) {
            // Filter checks for this specific permit and get the most recent one
            const permitChecks = checksResponse.data.filter(check => check.permitID === permitId);
            if (permitChecks.length > 0) {
              const latestCheck = permitChecks.reduce((latest, current) => {
                const latestDate = new Date(latest.checkedDate);
                const currentDate = new Date(current.checkedDate);
                return currentDate > latestDate ? current : latest;
              });
              setComplianceCheck(latestCheck);
            } else {
              throw new Error('No compliance checks found for this permit');
            }
          } else {
            throw new Error('Failed to fetch compliance checks');
          }
        }
      } catch (error) {
        console.error('Error fetching report:', error);
        setError('Failed to load compliance report. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchReport();
  }, [permitId]);

  const handleDownloadReport = async () => {
    if (!permit || !complianceCheck) return;

    try {
      setGeneratingPdf(true);
      setError(null);
      const reportElement = document.getElementById('compliance-report');
      if (!reportElement) {
        throw new Error('Report element not found');
      }

      const canvas = await html2canvas(reportElement, {
        scale: 2,
        logging: false,
        useCORS: true,
        backgroundColor: '#ffffff'
      });

      const imgWidth = 210; // A4 width in mm
      const pageHeight = 297; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const pdf = new jsPDF('p', 'mm', 'a4');
      
      let heightLeft = imgHeight;
      let position = 0;
      let pageData = canvas.toDataURL('image/jpeg', 1.0);

      pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save(`compliance-report-${permitId}-${new Date().toISOString().split('T')[0]}.pdf`);
    } catch (error) {
      console.error('Error generating PDF:', error);
      setError('Failed to generate PDF report. Please try again.');
    } finally {
      setGeneratingPdf(false);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        bgcolor: 'background.default',
        minHeight: '100vh',
        py: 4,
        mt: { xs: 7, sm: 8 },
        position: 'relative',
        zIndex: 0,
      }}
    >
      <Container maxWidth="lg">
        <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <IconButton
              onClick={() => navigate(-1)}
              sx={{
                bgcolor: 'background.paper',
                boxShadow: 1,
                '&:hover': {
                  bgcolor: alpha(theme.palette.primary.main, 0.08),
                },
              }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Box>
              <Typography variant="h4">Compliance Report</Typography>
              {permit && (
                <Box>
                  <Typography variant="subtitle1" color="text.secondary">
                    {permit.permitType}
                  </Typography>
                  {complianceCheck && (
                    <Box sx={{ mt: 1 }}>
                      {getStatusChip(complianceCheck.status)}
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
          <Button
            variant="contained"
            startIcon={generatingPdf ? <CircularProgress size={20} /> : <DownloadIcon />}
            onClick={handleDownloadReport}
            disabled={!permit || !complianceCheck || generatingPdf}
          >
            {generatingPdf ? 'Generating...' : 'Download Report'}
          </Button>
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        {complianceCheck && (
          <Box id="compliance-report">
            <Grid container spacing={4}>
              {/* Left Column */}
              <Grid item xs={12} md={5}>
                {/* Statistics Cards */}
                {complianceCheck.issues && (
                  <Grid container spacing={2} sx={{ mb: 4 }}>
                    {/* Total Issues */}
                    <Grid item xs={3}>
                      <Paper
                        sx={{
                          p: 2,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          bgcolor: 'background.paper',
                          borderRadius: '16px',
                        }}
                      >
                        <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                          Total
                        </Typography>
                        <Typography variant="h4" color="text.primary">
                          {JSON.parse(complianceCheck.issues).analysis.total_issues}
                        </Typography>
                      </Paper>
                    </Grid>

                    {/* High Severity */}
                    <Grid item xs={3}>
                      <Paper
                        sx={{
                          p: 2,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          bgcolor: 'background.paper',
                          borderRadius: '16px',
                        }}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                          <ErrorIcon sx={{ color: 'error.main', fontSize: 20 }} />
                          <Typography 
                            variant="body2" 
                            sx={{ 
                              color: 'error.main',
                              fontWeight: 500,
                              textTransform: 'uppercase',
                              letterSpacing: 0.5
                            }}
                          >
                            High
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.5 }}>
                          <Typography variant="h3" sx={{ color: 'error.main', fontWeight: 600 }}>
                             {JSON.parse(complianceCheck.issues).analysis.issues.filter((i: any) => i.severity === 'high').length}
                          </Typography>
                          <Typography variant="h5" sx={{ color: 'text.primary' }}>
                            /{JSON.parse(complianceCheck.issues).analysis.total_issues}
                          </Typography>
                        </Box>
                      </Paper>
                    </Grid>

                    {/* Medium Severity */}
                    <Grid item xs={3}>
                      <Paper
                        sx={{
                          p: 2,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          bgcolor: 'background.paper',
                          borderRadius: '16px',
                        }}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                          <WarningIcon sx={{ color: 'warning.main', fontSize: 20 }} />
                          <Typography 
                            variant="body2" 
                            sx={{ 
                              color: 'warning.main',
                              fontWeight: 500,
                              textTransform: 'uppercase',
                              letterSpacing: 0.5
                            }}
                          >
                            Medium
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.5 }}>
                          <Typography variant="h3" sx={{ color: 'warning.main', fontWeight: 600 }}>
                             {JSON.parse(complianceCheck.issues).analysis.issues.filter((i: any) => i.severity === 'medium').length}
                          </Typography>
                          <Typography variant="h5" sx={{ color: 'text.primary' }}>
                            /{JSON.parse(complianceCheck.issues).analysis.total_issues}
                          </Typography>
                        </Box>
                      </Paper>
                    </Grid>

                    {/* Low Severity */}
                    <Grid item xs={3}>
                      <Paper
                        sx={{
                          p: 2,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          bgcolor: 'background.paper',
                          borderRadius: '16px',
                        }}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                          <InfoIcon sx={{ color: 'info.main', fontSize: 20 }} />
                          <Typography 
                            variant="body2" 
                            sx={{ 
                              color: 'info.main',
                              fontWeight: 500,
                              textTransform: 'uppercase',
                              letterSpacing: 0.5
                            }}
                          >
                            Low
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.5 }}>
                          <Typography variant="h3" sx={{ color: 'info.main', fontWeight: 600 }}>
                             {JSON.parse(complianceCheck.issues).analysis.issues.filter((i: any) => i.severity === 'low').length}
                          </Typography>
                          <Typography variant="h5" sx={{ color: 'text.primary' }}>
                            /{JSON.parse(complianceCheck.issues).analysis.total_issues}
                          </Typography>
                        </Box>
                      </Paper>
                    </Grid>
                  </Grid>
                )}

                {/* Overview Section */}
                <Paper sx={{ p: 3, mb: 4, borderRadius: '16px' }}>
                  <Typography variant="h6" gutterBottom>
                    Overview
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Box>
                        <Typography variant="body2" color="text.secondary">
                          Check Date
                        </Typography>
                        <Typography variant="body1">
                          {new Date(complianceCheck.checkedDate).toLocaleDateString('en-US', {
                            month: '2-digit',
                            day: '2-digit',
                            year: 'numeric',
                          })}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Typography variant="body2" color="text.secondary">
                          Jurisdiction
                        </Typography>
                        <Typography variant="body1">
                          {jurisdiction ? `${jurisdiction.county}, ${jurisdiction.city}, ${jurisdiction.state}` : 'Loading...'}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Typography variant="body2" color="text.secondary">
                          County Code
                        </Typography>
                        <Typography variant="body1">
                          {jurisdiction ? `${jurisdiction.county} County Code` : 'Loading...'}
                        </Typography>
                      </Box>
                    </Grid>
                    {complianceCheck.issues && (
                      <>
                        <Grid item xs={12}>
                          <Box>
                            <Typography variant="body2" color="text.secondary">
                              Analysis Summary
                            </Typography>
                            <Typography variant="body1" paragraph>
                              {JSON.parse(complianceCheck.issues).analysis.summary}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Typography variant="body2" color="text.secondary">
                              Overall Risk Level:
                            </Typography>
                            <Chip
                              label={JSON.parse(complianceCheck.issues).analysis.risk_level.toUpperCase()}
                              color={
                                JSON.parse(complianceCheck.issues).analysis.risk_level === 'high'
                                  ? 'error'
                                  : JSON.parse(complianceCheck.issues).analysis.risk_level === 'medium'
                                  ? 'warning'
                                  : 'info'
                              }
                              size="small"
                            />
                          </Box>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Paper>

                {/* Recommendations Section */}
                {complianceCheck.issues && (
                  <Paper sx={{ p: 3, borderRadius: '16px' }}>
                    <Typography variant="h6" gutterBottom>
                      Recommendations
                    </Typography>
                    <Box component="ul" sx={{ pl: 2 }}>
                      {JSON.parse(complianceCheck.issues).analysis.recommendations.map((recommendation: string, index: number) => (
                        <Typography component="li" key={index} variant="body1" paragraph>
                          {recommendation}
                        </Typography>
                      ))}
                    </Box>
                  </Paper>
                )}
              </Grid>

              {/* Right Column - Issues List */}
              <Grid item xs={12} md={7}>
                <Paper sx={{ p: 3, borderRadius: '16px' }}>
                  <Typography variant="h6" gutterBottom>
                    Compliance Issues
                  </Typography>
                  <Grid container spacing={2}>
                    {complianceCheck.issues && (() => {
                      const issues = JSON.parse(complianceCheck.issues).analysis.issues;
                      const severityOrder: { [key: string]: number } = { high: 1, medium: 2, low: 3 };
                      const sortedIssues = [...issues].sort((a, b) => {
                        const severityA = a.severity.toLowerCase();
                        const severityB = b.severity.toLowerCase();
                        return (severityOrder[severityA] || 4) - (severityOrder[severityB] || 4);
                      });

                      return sortedIssues.map((issue: any, index: number) => (
                        <Grid item xs={12} key={issue.id || index}>
                          <Paper
                            sx={{
                              p: 2,
                              borderLeft: 6,
                              borderColor:
                                issue.severity === 'high'
                                  ? 'error.main'
                                  : issue.severity === 'medium'
                                  ? 'warning.main'
                                  : 'info.main',
                              // borderRadius: '16px',
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mb: 1,
                              }}
                            >
                              <Box>
                                <Typography variant="subtitle1">
                                  {issue.code_reference}
                                </Typography>
                                <Typography variant="caption" color="text.secondary">
                                  Document: {issue.document_name}
                                </Typography>
                              </Box>
                              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                <Chip
                                  label={issue.category.toUpperCase()}
                                  variant="outlined"
                                  size="small"
                                />
                                <Chip
                                  label={issue.severity.toUpperCase()}
                                  color={
                                    issue.severity === 'high'
                                      ? 'error'
                                      : issue.severity === 'medium'
                                      ? 'warning'
                                      : 'info'
                                  }
                                  size="small"
                                />
                              </Box>
                            </Box>
                            <Typography variant="body1" paragraph>
                              {issue.description}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" paragraph>
                              Remediation: {issue.remediation}
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              <Typography variant="caption" color="text.secondary">
                                Estimated Effort:
                              </Typography>
                              <Chip
                                label={issue.estimated_effort.toUpperCase()}
                                variant="outlined"
                                size="small"
                                color={
                                  issue.estimated_effort === 'high'
                                    ? 'error'
                                    : issue.estimated_effort === 'medium'
                                    ? 'warning'
                                    : 'info'
                                }
                              />
                            </Box>
                          </Paper>
                        </Grid>
                      ));
                    })()}
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default ComplianceReport; 