import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Paper,
  Button,
  Card,
  CardContent,
  CardActions,
  LinearProgress,
  useTheme,
  useMediaQuery,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
  Tab,
  Tabs,
  Alert,
  CircularProgress,
  alpha,
} from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import PieChartIcon from '@mui/icons-material/PieChart';
import DescriptionIcon from '@mui/icons-material/Description';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { StatusChipProps, Project } from '../types';
import { projectAPI } from '../apis';
import { isAuthenticated } from '../utils/auth';
import { grey } from '@mui/material/colors';

const MetricCard = styled(Card)(({ theme }: { theme: Theme }) => ({
  padding: '24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '16px',
  boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.08)',
  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0px 12px 32px rgba(0, 0, 0, 0.12)',
  },
  background: theme.palette.background.paper,
}));

const StyledTable = styled(Table)({
  '& .MuiTableCell-root': {
    borderBottom: '1px solid #f0f0f0',
    padding: '16px',
  },
  '& .MuiTableCell-head': {
    fontWeight: 600,
    backgroundColor: '#f8f9fa',
  },
});

const StatusChip = styled(Chip)<StatusChipProps>(({ status, theme }) => {
  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'in_progress':
        return {
          bg: alpha(theme.palette.warning.main, 0.1),
          color: theme.palette.warning.dark,
          border: theme.palette.warning.main,
        };
      case 'completed':
        return {
          bg: alpha(theme.palette.success.main, 0.1),
          color: theme.palette.success.dark,
          border: theme.palette.success.main,
        };
      case 'pending':
        return {
          bg: alpha(theme.palette.info.main, 0.1),
          color: theme.palette.info.dark,
          border: theme.palette.info.main,
        };
      default:
        return {
          bg: theme.palette.grey[100],
          color: theme.palette.grey[700],
          border: theme.palette.grey[300],
        };
    }
  };

  const colors = getStatusColor(status);
  return {
    backgroundColor: colors.bg,
    color: colors.color,
    border: `1px solid ${colors.border}`,
    fontWeight: 600,
    borderRadius: '20px',
    padding: '4px 12px',
  };
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: 'pointer',
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.04),
    transform: 'scale(1.001)',
  },
}));

const Dashboard: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [filter, setFilter] = useState('all');
  const [projects, setProjects] = useState<Project[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [metrics, setMetrics] = useState({
    activeProjects: 0,
    pendingReviews: 0,
    projectDocuments: 0,
  });

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        setLoading(true);
        setError(null);

        if (!isAuthenticated()) {
          navigate('/login', { state: { from: '/dashboard' } });
          return;
        }

        const response = await projectAPI.getProjects();
        
        if (response.status === 'success' && response.data) {
          setProjects(response.data);
          
          // Calculate metrics from project data
          const activeCount = response.data.filter(p => p.status === 'in_progress').length;
          const pendingCount = response.data.filter(p => p.status === 'pending').length;
          const totalProjects = response.data.length;
          
          setMetrics({
            activeProjects: activeCount,
            pendingReviews: pendingCount,
            projectDocuments: totalProjects,
          });
        } else {
          throw new Error(response.message || 'Failed to fetch projects');
        }
      } catch (error: any) {
        console.error('Error fetching projects:', error);
        if (error.status === 401) {
          navigate('/login', { state: { from: '/dashboard' } });
        } else {
          setError('Failed to load projects. Please try again later.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, [navigate]);

  const metricCards = [
    { 
      title: 'Total Projects', 
      value: metrics.projectDocuments.toString(), 
      icon: <PieChartIcon sx={{ fontSize: 40, color: theme.palette.primary.main }} /> 
    },
    { 
      title: 'Pending Approvals', 
      value: metrics.pendingReviews.toString(), 
      icon: <AssignmentIcon sx={{ fontSize: 40, color: 'grey' }} /> 
    },
    { 
      title: 'Queued Documents', 
      value: metrics.activeProjects.toString(), 
      icon: <DescriptionIcon sx={{ fontSize: 40, color: grey }} /> 
    },
  ];

  const filteredProjects = filter === 'all' 
    ? projects 
    : projects.filter(project => project.status.toLowerCase().replace(' ', '_') === filter);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ 
      pt: '84px',
      bgcolor: 'background.default', 
      minHeight: '100vh',
      pb: 4,
    }}>
      <Container maxWidth="lg">
        <Box sx={{ 
          mb: 4,
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}>
          <Typography 
            variant="h4" 
            sx={{ 
              fontWeight: 700,
              background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            Welcome back
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ fontSize: '1.1rem' }}>
            Here's an overview of your projects and recent activities
          </Typography>
        </Box>

        {error && (
          <Alert 
            severity="error" 
            sx={{ 
              mb: 3,
              borderRadius: 2,
              boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
            }}
          >
            {error}
          </Alert>
        )}

        <Box sx={{ 
          display: 'grid', 
          gridTemplateColumns: { 
            xs: '1fr', 
            sm: 'repeat(2, 1fr)', 
            md: 'repeat(3, 1fr)' 
          }, 
          gap: 3, 
          mb: 4 
        }}>
          {metricCards.map((metric) => (
            <MetricCard key={metric.title}>
              <Box>
                <Typography color="text.secondary" gutterBottom sx={{ fontSize: '1.1rem' }}>
                  {metric.title}
                </Typography>
                <Typography variant="h4" fontWeight="bold" color="text.primary">
                  {metric.value}
                </Typography>
              </Box>
              <Box sx={{
                p: 1.5,
                borderRadius: '12px',
                bgcolor: alpha(theme.palette.primary.main, 0.08),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                {metric.icon}
              </Box>
            </MetricCard>
          ))}
        </Box>

        <Box sx={{ mb: 4 }}>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center', 
            mb: 3,
            gap: 2,
          }}>
            <Typography variant="h5" fontWeight="600">Projects</Typography>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => navigate('/new-project')}
              sx={{
                borderRadius: '12px',
                textTransform: 'none',
                px: 3,
                py: 1,
                boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 6px 16px rgba(0,0,0,0.15)',
                },
                transition: 'all 0.2s ease',
              }}
            >
              Create New Project
            </Button>
          </Box>

          <Box sx={{ mb: 3 }}>
            <Tabs 
              value={filter} 
              onChange={(_, newValue) => setFilter(newValue)}
              sx={{
                '& .MuiTab-root': {
                  textTransform: 'none',
                  minWidth: 100,
                  fontWeight: 500,
                  fontSize: '1rem',
                },
                '& .Mui-selected': {
                  color: `${theme.palette.primary.main} !important`,
                },
                '& .MuiTabs-indicator': {
                  height: '3px',
                  borderRadius: '3px',
                },
              }}
            >
              <Tab label="All Projects" value="all" />
              <Tab label="In Progress" value="in_progress" />
              <Tab label="Completed" value="completed" />
              <Tab label="Pending" value="pending" />
            </Tabs>
          </Box>

          {filteredProjects.length === 0 ? (
            <Paper 
              sx={{ 
                p: 6, 
                textAlign: 'center', 
                borderRadius: '16px',
                boxShadow: '0 8px 32px rgba(0,0,0,0.08)',
                background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.05)} 0%, ${alpha(theme.palette.background.paper, 1)} 100%)`,
              }}
            >
              <Typography variant="h6" color="text.secondary" gutterBottom sx={{ mb: 2 }}>
                No projects found
              </Typography>
              <Typography variant="body1" color="text.secondary" paragraph>
                {filter === 'all' 
                  ? 'Start by creating your first project'
                  : `No ${filter.replace('_', ' ')} projects found`}
              </Typography>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => navigate('/new-project')}
                sx={{
                  borderRadius: '12px',
                  textTransform: 'none',
                  px: 4,
                  py: 1.5,
                  fontSize: '1rem',
                }}
              >
                Create Project
              </Button>
            </Paper>
          ) : (
            <Paper 
              sx={{ 
                borderRadius: '16px', 
                overflow: 'hidden',
                boxShadow: '0 8px 32px rgba(0,0,0,0.08)',
              }}
            >
              <StyledTable>
                <TableHead>
                  <TableRow>
                    <TableCell>Project Name</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Cost</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredProjects.map((project) => (
                    <StyledTableRow 
                      key={project.projectID}
                      onClick={() => navigate(`/project/${project.projectID}`)}
                    >
                      <TableCell>
                        <Typography fontWeight={600}>{project.name}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography color="text.secondary">{project.projectType}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography 
                          color="text.secondary" 
                          sx={{ 
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                          }}
                        >
                          {project.description}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <StatusChip
                          label={project.status.replace('_', ' ').replace(/\b\w/g, l => l.toUpperCase())}
                          status={project.status}
                          size="small"
                        />
                      </TableCell>
                      <TableCell>
                        <Typography fontWeight={500}>
                          ${project.estimatedCost.toLocaleString()}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/project/${project.projectID}/edit`);
                          }}
                          sx={{
                            color: 'text.secondary',
                            '&:hover': {
                              color: 'primary.main',
                              bgcolor: alpha(theme.palette.primary.main, 0.1),
                            },
                          }}
                        >
                          <MoreHorizIcon />
                        </IconButton>
                      </TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </Paper>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default Dashboard; 